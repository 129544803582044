import axios from "axios";
import {
  myFirebase
} from "../firebase/firebase";
import {
  currentUserData
} from "./user";

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";

export const SIGNUP_REQUEST = "SIGNUP_REQUEST";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAILURE = "SIGNUP_FAILURE";

export const VERIFY_REQUEST = "VERIFY_REQUEST";
export const VERIFY_RECEIVE = "VERIFY_RECEIVE";

export const PASSWORD_RESET_REQUEST = "PASSWORD_RESET_REQUEST";
export const PASSWORD_RESET_SUCCESS = "PASSWORD_RESET_SUCCESS";

export const VERIFY_EMAIL_REQUEST = "VERIFY_EMAIL_REQUEST";
export const VERIFY_EMAIL_SUCCESS = "VERIFY_EMAIL_SUCCESS";
export const VERIFY_EMAIL_FAILURE = "VERIFY_EMAIL_FAILURE";



const requestLogin = creds => {
  return {
    type: LOGIN_REQUEST,
    creds
  };
};

const receiveLogin = user => {
  return {
    type: LOGIN_SUCCESS,
    user: user
  };
};

const loginError = message => {
  return {
    type: LOGIN_FAILURE,
    message
  };
};

const requestLogout = () => {
  return {
    type: LOGOUT_REQUEST
  };
};

const requestPasswordReset = () => {
  return {
    type: PASSWORD_RESET_REQUEST
  };
};

const receiveLogout = () => {
  return {
    type: LOGOUT_SUCCESS
  };
};

const receivePasswordReset = () => {
  return {
    type: PASSWORD_RESET_SUCCESS
  };
};

const logoutError = () => {
  return {
    type: LOGOUT_FAILURE
  };
};

const verifyRequest = () => {
  return {
    type: VERIFY_REQUEST
  };
};

const verifyReceive = () => {
  return {
    type: VERIFY_RECEIVE
  };
};

const requestSignup = creds => {
  return {
    type: SIGNUP_REQUEST,
    isFetching: true,
    user: creds
  };
};

const receiveSignup = () => {
  return {
    type: SIGNUP_SUCCESS,
    isFetching: false
  };
};

const signupError = message => {
  return {
    type: SIGNUP_FAILURE,
    isFetching: false,
    message
  };
};

const requestVerifyEmail = () => ({
  type: VERIFY_EMAIL_REQUEST,
})

const receiveVerifyEmail = isVerified => ({
  type: VERIFY_EMAIL_SUCCESS,
  isVerified
})

const verifyEmailError = message => {
  return {
    type: VERIFY_EMAIL_FAILURE,
    isFetching: false,
    message
  }
}

export const loginUser = creds => dispatch => {
  dispatch(requestLogin(creds));
  myFirebase
    .auth()
    .signInWithEmailAndPassword(creds.email, creds.password)
    .then(user => {
      dispatch(receiveLogin(user));
      dispatch();
    })
    .catch(error => {
      dispatch(loginError(error.message));
    });
};

export const signupUser = (creds, callback) => dispatch => {
  dispatch(requestSignup(creds));
  dispatch(requestVerifyEmail());
  console.log("here")

  return axios
    .get("/api/v1/verifyemail/", {
      params: {
        email: creds.email
      }
    })
    .then(json => {
      dispatch(receiveVerifyEmail(json.data.isVerified));
      if (json.data.isVerified) {
        axios
          .post("/api/v1/signup/", {
            email: creds.email,
            password: creds.password,
            displayName: creds.name,
            firstName: creds.firstName,
            lastName: creds.lastName
          })
          .then(json => {
            dispatch(receiveSignup(json.data));
            callback();
          })
          .catch(error => {
            dispatch(signupError(error));
          });
      }
    })
    .catch(error => {
      dispatch(verifyEmailError(error));
      dispatch(signupError(error));
    });
};


export const logoutUser = () => dispatch => {
  dispatch(requestLogout());
  myFirebase
    .auth()
    .signOut()
    .then(function () {
      dispatch(receiveLogout());
    })
    .catch(function (error) {
      dispatch(logoutError());
    });
};

export const verifyAuth = () => dispatch => {
  dispatch(verifyRequest());
  myFirebase.auth().onAuthStateChanged(user => {
    if (user !== null) {
      dispatch(receiveLogin(user));
      console.log("lol")
      dispatch(currentUserData(user.uid));
    }
    dispatch(verifyReceive());
  });
};

export const updateEmail = (email, callback) => (dispatch) => {
  myFirebase
    .auth().currentUser.updateEmail(email).then(() => {
      callback()
    })
};