import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { updateProfileData } from "../actions/user";
import { updateEmail } from "../actions/authentication";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Tabs from "@material-ui/core/Tabs";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Typography from "@material-ui/core/Typography";
import "../styles/ribbon.css";

import Tab from "@material-ui/core/Tab";
import Button from "@material-ui/core/Button";

class EditProfile extends React.Component {
  state = {
    value: 0,
    firstName: "",
    lastName: "",
    city: "",
    state: "",
    zipcode: "",
    email: "",
    confirmEmail: "",
    emailSuccessfullyUpdated: false
  };

  componentDidUpdate(prevProps) {
    if (prevProps.userData.firstName !== this.props.userData.firstName) {
      this.setState({ firstName: this.props.userData.firstName });
    }

    if (prevProps.userData.lastName !== this.props.userData.lastName) {
      this.setState({ lastName: this.props.userData.lastName });
    }

    if (prevProps.userData.description !== this.props.userData.description) {
      this.setState({ description: this.props.userData.description });
    }
  }

  componentDidMount() {
    this.setState({
      email: this.props.authData.user.email,
      firstName: this.props.userData.firstName,
      lastName: this.props.userData.lastName,
      zipcode: this.props.userData.zipcode
    });
  }

  handleInputChange = (key, value) => {
    this.setState({ [key]: value });
  };

  handleEmailChange = event => {
    this.setState({ emailSuccessfullyUpdated: false });
    this.setState({ email: event.target.value });
  };

  handleConfirmEmailChange = event => {
    this.setState({ emailSuccessfullyUpdated: false });
    this.setState({ confirmEmail: event.target.value });
  };

  handleTabChange = (event, value) => {
    this.setState({ value });
  };

  handleEmailUpdateCallback = () => {
    this.setState({ emailSuccessfullyUpdated: true });
  };

  handleSave = () => {
    if (this.state.value === 0) {
      this.props.dispatch(
        updateProfileData(this.props.authData.user.uid, {
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          description: this.state.description
        })
      );
    } else if (this.state.value === 1) {
      if (this.state.email === this.state.confirmEmail) {
        this.props.dispatch(
          updateEmail(this.state.email, this.handleEmailUpdateCallback)
        );
      }
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <Fragment>
        <Grid className={classes.root} container spacing={24}>
          <Grid container spacing={16} justify="center">
            <Grid item xs={12} sm={12} md={10} lg={10}>
              <div
                style={{
                  padding: "20px",
                  textAlign: "left",
                  marginBottom: "10px"
                }}
              >
                <Typography variant="h4">Edit Profile</Typography>
                <hr />
              </div>
              <Paper className={`${classes.paper}`}>
                <ValidatorForm
                  ref="form"
                  onSubmit={this.handleSave}
                  instantValidate={false}
                  onError={errors => console.log(errors)}
                >
                  <div>
                    <Tabs
                      value={this.state.value}
                      onChange={this.handleTabChange}
                      indicatorColor="primary"
                      textColor="primary"
                    >
                      <Tab label="Personal info" />
                      <Tab label="Email" />
                      <Tab label="Password" />
                    </Tabs>
                    <br />
                    <br />
                  </div>
                  {this.state.value === 0 && (
                    <Fragment>
                      <div className={classes.textLeft}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          label="First Name"
                          id="margin-none"
                          value={
                            this.state.firstName ? this.state.firstName : ""
                          }
                          className={`${classes.textField} ${classes.spacing}`}
                          onChange={evt =>
                            this.handleInputChange(
                              "firstName",
                              evt.target.value
                            )
                          }
                          margin="normal"
                        />
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          label="Last Name"
                          id="margin-dense"
                          className={`${classes.textField} ${classes.spacing}`}
                          onChange={evt =>
                            this.handleInputChange("lastName", evt.target.value)
                          }
                          value={this.state.lastName ? this.state.lastName : ""}
                          margin="normal"
                        />
                      </div>
                      <div className={classes.textLeft}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          label="Description"
                          id="margin-normal"
                          multiline={true}
                          rows={4}
                          className={`${classes.textField} ${classes.spacing}`}
                          margin="normal"
                          value={
                            this.state.description ? this.state.description : ""
                          }
                          onChange={evt =>
                            this.handleInputChange(
                              "description",
                              evt.target.value
                            )
                          }
                        />
                        <br />
                        <br />
                        <Button
                          className={`${classes.spacing}`}
                          color="primary"
                          variant="contained"
                          onClick={this.handleSave}
                          type="submit"
                        >
                          Save
                        </Button>
                      </div>
                    </Fragment>
                  )}
                  {this.state.value === 1 && (
                    <div className={classes.textLeft}>
                      {this.state.emailSuccessfullyUpdated && (
                        <p>
                          Your email has been successfully updated. Check your
                          email to comfirm this change.
                        </p>
                      )}
                      <TextField
                        label="Email"
                        id="margin-normal"
                        className={`${classes.textField} ${classes.spacing}`}
                        margin="normal"
                        onChange={this.handleEmailChange}
                        value={this.state.email ? this.state.email : ""}
                      />
                      <TextField
                        label="Confirm Email"
                        defaultValue=""
                        id="margin-normal"
                        className={`${classes.textField} ${classes.spacing}`}
                        margin="normal"
                        onChange={this.handleConfirmEmailChange}
                        value={
                          this.state.confirmEmail ? this.state.confirmEmail : ""
                        }
                      />
                      <br />
                      <br />
                      <Button
                        className={`${classes.spacing}`}
                        color="primary"
                        variant="contained"
                        onClick={this.handleSave}
                      >
                        Update Email
                      </Button>
                    </div>
                  )}
                  {this.state.value === 2 && (
                    <div className={classes.textLeft}>
                      <TextField
                        label="Password"
                        id="margin-normal"
                        className={`${classes.textField} ${classes.spacing}`}
                        margin="normal"
                      />
                      <TextField
                        label="Confirm Password"
                        defaultValue=""
                        id="margin-normal"
                        className={`${classes.textField} ${classes.spacing}`}
                        margin="normal"
                      />
                      <br />
                      <br />
                      <Button
                        className={`${classes.spacing}`}
                        color="primary"
                        variant="contained"
                        onClick={this.handleSave}
                      >
                        Update Password
                      </Button>
                    </div>
                  )}
                </ValidatorForm>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

EditProfile.propTypes = {
  classes: PropTypes.object.isRequired
};

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: "5em"
  },
  card: {
    display: "flex"
  },
  details: {
    display: "flex",
    flexDirection: "column"
  },
  content: {
    flex: "1 0 auto"
  },
  avatar: {
    borderRadius: "100%",
    width: "100%",
    maxHeight: "150px",
    maxWidth: "150px",
    minWidth: "150px"
  },
  uploadButton: {
    whiteSpace: "nowrap",
    display: "block"
  },
  nowrap: {
    whiteSpace: "nowrap"
  },
  fr: {
    textAlign: "right"
  },
  textLeft: {
    textAlign: "left"
  },
  fl: {
    float: "left"
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing.unit,
    paddingBottom: theme.spacing.unit
  },
  playIcon: {
    height: 38,
    width: 38
  },
  info: {
    borderColor: "#2196F3",
    color: "dodgerblue"
  },
  infoHover: {
    background: "#2196F3",
    color: "white"
  },
  sendMsg: {
    paddingTop: 20
  },
  verticalAlign: {
    verticalAlign: "middle"
  },
  nudgeBottom: {
    marginBottom: "5px"
  },
  marginLeft: "5%",
  center: { textAlign: "center", justify: "center" },
  textField: {
    width: 200
  },
  spacing: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit
  }
});

function mapStateToProps(state) {
  return {
    userData: state.user.user,
    authData: state.auth
  };
}

export default withStyles(styles)(connect(mapStateToProps)(EditProfile));
