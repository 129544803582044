import axios from "axios";
import { db } from "../firebase/firebase";
import { myFirebase } from "../firebase/firebase";

import _ from "lodash";

export const REQUEST_JOBS = "REQUEST_JOBS";
export const RECEIVE_JOBS = "RECEIVE_JOBS";

const requestJobs = () => {
  return {
    type: REQUEST_JOBS
  };
};

const receiveJobs = data => {
  return {
    type: RECEIVE_JOBS,
    items: data
  };
};

export const fetchJobs = payload => (dispatch, getState) => {
  const docRef = db.collection("jobs");
  dispatch(requestJobs());
  docRef
    .where("startDate", ">", payload.startDate)
    .get()
    .then(querySnapshot => {
      const data = [];
      console.log(getState())
      querySnapshot.forEach(doc => {
        //console.log(doc.data())
        if (doc.data().status === "Open") {
          data.push({ ...doc.data(), id: doc.id });
        }
      });
      dispatch(receiveJobs(data));
    });
};
