import React from "react";
import { Button, Card, CardContent, Grid, Typography } from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";
import { loginUser } from "../actions/authentication";
import { Redirect, Link } from "react-router-dom";
import { connect } from "react-redux";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

class Login extends React.Component {
  state = {
    email: "",
    password: "",
    submitted: false,
    processed: false,
    invalidLogin: "You have entered an invalid username or password"
  };

  handleSubmit = () => {
    this.setState({ submitted: true, processed: true }, () => {
      setTimeout(() => this.setState({ submitted: false }), 5000);
      this.props.dispatch(
        loginUser({
          email: this.state.email,
          password: this.state.password
        })
      );
      return this.props.isAuthenticated;
    });
  };

  handleEmailChange = event => {
    const email = event.target.value;
    this.setState({ email, processed: false });
  };

  handleEmailBlur = event => {
    this.refs[event.target.name].validate(event.target.value);
  };

  handlePasswordBlur = event => {
    this.refs[event.target.name].validate(event.target.value);
  };

  handlePasswordChange = event => {
    const password = event.target.value;
    this.setState({ password, processed: false });
  };

  render() {
    const { classes } = this.props;
    const { from } = this.props.location.state || { from: { pathname: "/" } };
    const { email, password, submitted, processed } = this.state;
    if (this.props.isAuthenticated) {
      return <Redirect to={from} />;
    } else {
      return (
        <div>
          <Grid
            container
            alignItems="center"
            justify="center"
            className={`${classes.fullHeight} ${classes.gridBackground}`}
          >
            <Grid item sm={4} md={3} xs={8}>
              <Card
                style={styles}
                className={classes.card + " " + classes.textCenter}
              >
                <CardContent>
                  <ValidatorForm
                    ref="form"
                    onSubmit={this.handleSubmit}
                    instantValidate={false}
                    onError={errors => console.log(errors)}
                  >
                    <TextValidator
                      label="Email"
                      ref="email"
                      name="email"
                      onChange={this.handleEmailChange}
                      onBlur={this.handleEmailBlur}
                      fullWidth
                      value={email}
                      validators={["required", "isEmail"]}
                      errorMessages={[
                        "Email is required",
                        "Email is not valid"
                      ]}
                    />
                    <TextValidator
                      label="Password"
                      name="password"
                      ref="password"
                      type="password"
                      onChange={this.handlePasswordChange}
                      onBlur={this.handlePasswordBlur}
                      fullWidth
                      value={password}
                      validators={["required"]}
                      errorMessages={["Password is required"]}
                    />
                    <br />
                    <br />
                    <Button
                      variant="contained"
                      raised="true"
                      className={
                        classes.button + " " + classes.label + " " + classes.fl
                      }
                      classes={{
                        label: classes.label // class name, e.g. `classes-nesting-label-x`
                      }}
                      styles={styles.button}
                      type="submit"
                      disabled={submitted && this.props.isFetching}
                    >
                      Sign in
                    </Button>
                  </ValidatorForm>
                  {processed &&
                    !this.props.isFetching &&
                    !this.props.isAuthenticated && (
                      <Typography
                        variant="body1"
                        component="p"
                        className={`${classes.nudgeTop} ${classes.red}`}
                      >
                        {this.state.invalidLogin}
                      </Typography>
                    )}
                  <br />
                  <Grid container spacing={24}>
                    <Grid item xs={6}>
                      <Typography gutterBottom variant="body2">
                        <Link
                          to="/signup"
                          className={`${classes.fl} ${classes.link}`}
                        >
                          Sign up
                        </Link>
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography gutterBottom variant="body2">
                        <a
                          href=""
                          className={`${classes.fr} ${classes.nowrap} ${
                            classes.link
                          }`}
                        >
                          Forgot password?
                        </a>
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </div>
      );
    }
  }
}

const styles = {
  button: {
    paddingLeft: "20px",
    paddingRight: "20px",
    width: "100%",
    background:
      "linear-gradient(to right top, #566866, #41959E, #77ACA2, #9DBEBB, #F4E9CD)",
    color: "white",
    marginRight: "5px"
  },
  gridBackground: {
    background:
      "linear-gradient(to right top, #031926, #41959E, #77ACA2, #9DBEBB, #F4E9CD)"
    // "linear-gradient(to right top, #00c9b7, #00c8d5, #00c4ed, #3bbefb, #7ab6ff)"
  },
  fullHeight: {
    height: "100vh"
  },
  margin: 15,
  card: {
    minWidth: 275,
    paddingTop: 30,
    paddingBottom: 30,
    paddingLeft: 20,
    paddingRight: 20
  },
  title: {
    marginBottom: 16,
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  },
  textCenter: {
    textAlign: "center"
  },
  media: {
    height: 0,
    paddingTop: "56.25%" // 16:9
  },
  label: {
    textTransform: "capitalize"
  },
  fl: {
    float: "left"
  },
  fr: {
    float: "right"
  },
  nowrap: {
    whiteSpace: "nowrap"
  },
  link: {
    textDecoration: "none",
    color: "rgb(94, 191, 240)"
  },
  white: {
    color: "white"
  },
  nudgeTop: {
    paddingTop: "45px"
  },
  red: {
    color: "red"
  }
};

function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated
  };
}
export default withStyles(styles)(connect(mapStateToProps)(Login));
