import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import TextField from "@material-ui/core/TextField";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { fetchAddAddress } from "../actions/address";

const allStates = [
  "Alabama",
  "Alaska",
  "American Samoa",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "District of Columbia",
  "Federated States of Micronesia",
  "Florida",
  "Georgia",
  "Guam",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Marshall Islands",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Northern Mariana Islands",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Palau",
  "Pennsylvania",
  "Puerto Rico",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virgin Island",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming"
];

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  };
}

const styles = theme => ({
  paper: {
    position: "absolute",
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none"
  },
  addButton: {
    marginTop: 20,
    marginLeft: 20
  },
  formControl: {
    margin: theme.spacing.unit
  },
  selectField: {
    marginTop: 16,
    width: "100%"
  }
});

class AddAddressModal extends React.Component {
  state = {
    open: false,
    name: "",
    type: "Driving Location",
    street: "",
    city: "",
    state: "",
    zip: ""
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({
      open: false,
      name: "",
      type: "Driving Location",
      street: "",
      city: "",
      state: "",
      zip: ""
    });
  };

  handleSetProperty = key => ({ target }) => {
    this.setState({ [key]: target.value });
  };

  handleSubmit = () => {
    const { name, type, zip, street, state, city } = this.state;
    this.props.dispatch(
      fetchAddAddress({ name, type, zip, street, state, city })
    );
    this.handleClose();
  };

  render() {
    const { classes } = this.props;

    return (
      <div>
        <Button
          variant="fab"
          color="primary"
          aria-label="Add"
          className={classes.addButton}
          onClick={this.handleOpen}
        >
          <AddIcon />
        </Button>
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.state.open}
          onClose={this.handleClose}
        >
          <div style={getModalStyle()} className={classes.paper}>
            <Typography variant="h6" id="modal-title">
              Add an Address
            </Typography>
            <Grid container>
              <Grid item xs={5} sm={5} md={5} className={classes.formControl}>
                <TextField
                  label="Home/Office"
                  className={classes.textField}
                  value={this.state.name}
                  onChange={this.handleSetProperty("name")}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={5} sm={5} md={5} className={classes.formControl}>
                <FormControl variant="outlined" className={classes.selectField}>
                  <InputLabel htmlFor="type">Type</InputLabel>
                  <Select
                    value={this.state.type}
                    onChange={this.handleSetProperty("type")}
                    input={
                      <OutlinedInput name="type" id="type" labelWidth={50} />
                    }
                  >
                    <MenuItem value={"Driving Location"}>
                      Driving Location
                    </MenuItem>
                    <MenuItem value={"Business Address"}>
                      Business Address
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={5} sm={5} md={5} className={classes.formControl}>
                <TextField
                  label="Street"
                  className={classes.textField}
                  value={this.state.street}
                  onChange={this.handleSetProperty("street")}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={5} sm={5} md={5} className={classes.formControl}>
                <TextField
                  label="City"
                  className={classes.textField}
                  value={this.state.city}
                  onChange={this.handleSetProperty("city")}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={5} sm={5} md={5} className={classes.formControl}>
                <FormControl variant="outlined" className={classes.selectField}>
                  <InputLabel htmlFor="state">State</InputLabel>
                  <Select
                    value={this.state.state}
                    onChange={this.handleSetProperty("state")}
                    input={
                      <OutlinedInput name="state" id="state" labelWidth={50} />
                    }
                  >
                    {allStates.map(value => (
                      <MenuItem value={value}>{value}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={5} sm={5} md={5} className={classes.formControl}>
                <TextField
                  label="Zip"
                  className={classes.textField}
                  value={this.state.zip}
                  onChange={this.handleSetProperty("zip")}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
              <Button
                variant="fab"
                color="primary"
                aria-label="Add"
                className={classes.addButton}
                onClick={this.handleSubmit}
              >
                <AddIcon />
              </Button>
            </Grid>
          </div>
        </Modal>
      </div>
    );
  }
}

AddAddressModal.propTypes = {
  classes: PropTypes.object.isRequired
};

// We need an intermediary variable for handling the recursive nesting.
export default withStyles(styles)(AddAddressModal);
