import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import AddAddressModal from "./AddAddressModal";
import { fetchAddress, deleteAddress } from "../actions/address";
const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto"
  },
  addressType: {
    margin: 50
  },
  table: {
    minWidth: 700
  },
  addButton: {
    marginTop: 20,
    marginLeft: 20
  }
});

let id = 0;
function createData(title, type, street, city, state, zip) {
  id += 1;
  return { id, title, type, street, city, state, zip };
}

const rows = [
  createData(
    "Home",
    "Driving Location",
    "574 Molly Lane",
    "Chattanooga",
    "TN",
    37402
  ),
  createData(
    "Work",
    "Driving Location",
    "110 Market Street",
    "Chattanooga",
    "TN",
    37402
  ),
  createData(
    "Chattanooga EyeCare",
    "Business Location",
    "550 Market Street",
    "Chattanooga",
    "TN",
    37402
  )
];

class AddressTable extends React.Component {
  componentDidMount() {
    this.props.dispatch(fetchAddress());
  }
  handleDeleteAddress = uid => () => {
    this.props.dispatch(deleteAddress(uid));
  };
  render() {
    const { classes, dispatch, addresses } = this.props;

    return (
      <React.Fragment>
        <Paper className={classes.root}>
          {addresses.length > 0 ? (
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Title</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Street</TableCell>
                  <TableCell>City</TableCell>
                  <TableCell>State</TableCell>
                  <TableCell>Zip</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {addresses.map(row => {
                  return (
                    <TableRow key={row.id}>
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell>{row.type}</TableCell>
                      <TableCell>{row.street}</TableCell>
                      <TableCell>{row.city}</TableCell>
                      <TableCell>{row.state}</TableCell>
                      <TableCell numeric>{row.zip}</TableCell>
                      <TableCell>
                        <Button
                          variant="fab"
                          color="secondary"
                          aria-label="Add"
                          className={classes.button}
                          onClick={this.handleDeleteAddress(row.id)}
                          mini
                        >
                          <DeleteIcon />
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          ) : (
            <Typography
              component="h6"
              variant="h5"
              className={classes.addressType}
              gutterBottom
            >
              It looks like you have no added addresses! Click the button below
              to add a new business address.
            </Typography>
          )}
        </Paper>
        <AddAddressModal dispatch={dispatch} />
      </React.Fragment>
    );
  }
}

AddressTable.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    addresses: state.address.items
  };
};
export default connect(mapStateToProps)(withStyles(styles)(AddressTable));
