import React from "react";
import { Link } from "react-router-dom";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import SearchIcon from "@material-ui/icons/Search";
import HomeIcon from "@material-ui/icons/Home";
import AddIcon from "@material-ui/icons/AddBox";
import GroupIcon from "@material-ui/icons/Group";

const baseStyle = {
  textDecoration: "none"
};

const DrawerLinks = props => (
  <React.Fragment>
    <Link to="/dashboard/browse" style={baseStyle}>
      <ListItem button>
        <ListItemIcon>
          <SearchIcon />
        </ListItemIcon>
        <ListItemText primary="Browse" />
      </ListItem>
    </Link>
    {!props.userData.isLooking && (
      <Link to="/dashboard/list" style={baseStyle}>
        <ListItem button>
          <ListItemIcon>
            <AddIcon />
          </ListItemIcon>
          <ListItemText primary="Add Listing" />
        </ListItem>
      </Link>
    )}
  </React.Fragment>
);

export default DrawerLinks;
