import React from "react";

class Groups extends React.Component {
  render() {
    return (
      <div>
        <h1>Groups</h1>
        <h2>Coming Soon</h2>
      </div>
    );
  }
}

export default Groups;
