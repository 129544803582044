import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import OpenPositions from "../components/list/OpenPositions";
import Availability from "../components/list/Availablility";
import Typography from "@material-ui/core/Typography";
const styles = {
  root: {
    flexGrow: 1
  }
};

class List extends React.Component {
  state = {
    value: 0
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <Typography component="h2" variant="h4" gutterBottom>
          Create a job listing.
        </Typography>
        <Paper className={classes.root}>
          <Tabs
            value={this.state.value}
            onChange={this.handleChange}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            {/*<Tab label="Availablility" />*/}
            <Tab label="Open Position" />
          </Tabs>
          {this.state.value === 1 && <Availability />}
          {this.state.value === 0 && <Availability />}
        </Paper>
      </React.Fragment>
    );
  }
}

List.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(List);
