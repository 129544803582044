import { REQUEST_JOBS, RECEIVE_JOBS } from "../actions/browse";

export const jobs = (
  state = {
    isFetching: false,
    items: []
  },
  action
) => {
  switch (action.type) {
    case REQUEST_JOBS:
      return {
        ...state,
        isFetching: true
      };
    case RECEIVE_JOBS:
      return {
        ...state,
        isFetching: false,
        items: action.items
      };
    default:
      return state;
  }
};
