import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { fetchLicense } from "../../actions/licenseVerification";
import { withStyles } from "@material-ui/core/styles";
import LicenseTable from "./LicenseTable";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Grid from "@material-ui/core/Grid";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import MoreInfo from "./MoreInfo";
import { submitLicense } from "../../actions/licenseVerification";
import { Redirect } from "react-router";
import Paper from "@material-ui/core/Paper";

const styles = theme => ({
  root: {
    width: "100%",
    textAlign: "center"
  },
  rootForm: {
    display: "flex",
    flexWrap: "wrap",
    textAlign: "left",
    margin: "auto",
    minWidth: 300,
    width: "50%"
  },
  success: {
    backgroundColor: "green",
    color: "white"
  },
  formControl: {
    margin: theme.spacing.unit
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2
  },
  stepperRoot: {
    backgroundColor: theme.palette.background.default
  },
  backButton: {
    marginRight: theme.spacing.unit
  },
  instructions: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit
  },
  nudge: {
    padding: "20px"
  },
  fullWidth: {
    width: "100%"
  }
});

class IntroForm extends React.Component {
  state = {
    activeStep: 0,
    accountType: "",
    accountTypeValid: true,
    npiNumber: "",
    npiNumberValid: true,
    selectedLicense: null
  };
  getSteps = () => {
    return ["License Verification"];
  };

  handleNext = () => {
    const { activeStep } = this.state;
    let newState = {};
    let shouldAdvance = true;
    if (activeStep === 0) {
      if (this.state.selectedLicense !== null) {
        shouldAdvance = true;
      } else {
        shouldAdvance = false;
      }
    }
    if (shouldAdvance) {
      this.setState({
        activeStep: activeStep + 1,
        ...newState
      });
    } else {
      this.setState({
        ...newState
      });
    }
  };

  handleBack = () => {
    const { activeStep } = this.state;
    this.setState({
      activeStep: activeStep - 1
    });
  };

  handleReset = () => {
    this.setState({
      activeStep: 0
    });
  };

  handleAccountChange = evt => {
    this.setState({ accountType: evt.target.value, accountTypeValid: true });
  };

  handleNpiChange = evt => {
    if (evt.target.value.length <= 10) {
      this.setState({
        npiNumber: evt.target.value.replace(/[^0-9]/g, "")
      });
    }
  };

  isNextDisabled = () => {
    console.log(this.state.activeStep);
    if (
      this.state.activeStep === 1 &&
      (this.state.accountType === 1 || this.state.accountType === 3)
    ) {
      return this.state.selectedLicense === null;
    }
    return false;
  };

  verifyNpiNumber = () => {
    if (this.state.npiNumber) {
      this.setState({ npiNumberValid: true });
      this.props.dispatch(fetchLicense({ number: this.state.npiNumber }));
    } else {
      this.setState({ npiNumberValid: false });
    }
  };

  handleSelectLicense = license => {
    this.setState({ selectedLicense: license });
  };

  handleSubmit = () => {
    this.props.dispatch(submitLicense(this.state.selectedLicense));
    this.setState({ redirect: true });
  };

  render() {
    const { classes } = this.props;
    const steps = this.getSteps();
    const {
      activeStep,
      accountType,
      accountTypeValid,
      npiNumber,
      npiNumberValid
    } = this.state;

    return (
      <Fragment>
        <Grid container spacing={16} justify="center">
          <Grid item xs={12} sm={12} md={10} lg={10}>
            <div
              style={{
                padding: "20px",
                textAlign: "left",
                marginBottom: "10px"
              }}
            >
              <Typography variant="h4">Get Verified</Typography>
              <hr />
            </div>
            <Paper className={classes.nudge}>
              <div className={classes.root}>
                {this.state.redirect && <Redirect to={"/dashboard/browse"} />}
                <Stepper
                  className={classes.stepperRoot}
                  activeStep={activeStep}
                  alternativeLabel
                >
                  {steps.map(label => {
                    return (
                      <Step key={label} className={classes.fullWidth}>
                        <StepLabel>{label}</StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
                <div>
                  {this.state.activeStep === steps.length ? (
                    <div>
                      <Typography className={classes.instructions}>
                        All steps completed
                      </Typography>
                      <Button onClick={this.handleReset}>Go To Browse</Button>
                    </div>
                  ) : (
                    <div>
                      {activeStep === 0 && (
                        <React.Fragment>
                          <form className={classes.rootForm} autoComplete="off">
                            <Grid
                              container
                              justify="center"
                              alignItems="center"
                              alignContent="center"
                              spacing={16}
                            >
                              <Grid item xs={9}>
                                <FormControl
                                  fullWidth={true}
                                  className={classes.formControl}
                                >
                                  <InputLabel htmlFor="account-type">
                                    Enter your NPI Number
                                  </InputLabel>
                                  <Input
                                    name=""
                                    onChange={this.handleNpiChange}
                                    value={npiNumber}
                                    error={!npiNumberValid}
                                  />
                                  {!npiNumberValid && (
                                    <FormHelperText error={true}>
                                      The number you entered is not valid,
                                      please try again.
                                    </FormHelperText>
                                  )}
                                </FormControl>
                              </Grid>
                              <Grid item xs={2}>
                                <Button
                                  variant="contained"
                                  className={classes.success}
                                  onClick={this.verifyNpiNumber}
                                >
                                  Verify
                                </Button>
                              </Grid>
                            </Grid>
                          </form>
                          {this.props.verifyCount > 0 && (
                            <LicenseTable
                              verifyResults={this.props.verifyResults}
                              verifyFetching={this.props.verifyFetching}
                              selectedLicense={this.state.selectedLicense}
                              handleSelectLicense={this.handleSelectLicense}
                            />
                          )}
                        </React.Fragment>
                      )}
                      {activeStep === 1 && (
                        <Grid
                          container
                          justify="center"
                          alignItems="center"
                          alignContent="center"
                          spacing={16}
                        >
                          <Grid item xs={9}>
                            <MoreInfo
                              selectedLicense={this.state.selectedLicense}
                            />
                          </Grid>
                        </Grid>
                      )}
                      <div>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={this.handleSubmit}
                          disabled={!this.state.selectedLicense}
                        >
                          Finish
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Paper>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

IntroForm.propTypes = {
  classes: PropTypes.object
};

function mapStateToProps(state) {
  return {
    verifyFetching: state.verifyLicense.isFetching,
    verifyResults: state.verifyLicense.results,
    verifyCount: state.verifyLicense.count
  };
}

export default withStyles(styles)(connect(mapStateToProps)(IntroForm));
