import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import TextField from "@material-ui/core/TextField";
import Icon from "@material-ui/core/Icon";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import AddressInput from "../AddressInput";
import AddDatesDialog from "../AddDatesDialog";
import { fetchAddList } from "../../actions/list";
const styles = theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "90%"
  },
  container: {
    flexGrow: 1
  },
  formItem: {
    //textAlign: "left"
  },
  dense: {
    marginTop: 16
  },
  menu: {
    width: 200
  },
  rightIcon: {
    marginLeft: theme.spacing.unit
  },
  button: {
    marginTop: 15,
    marginBottom: 25,
    width: "100%"
  },

  button: {
    margin: 50,
    width: "60%"
  }
});

class Availability extends React.Component {
  state = {
    job: "Optometrist Needed",
    id: "",
    dates: [],
    amount: "",
    startDate: "",
    endDate: "",
    street: "",
    state: "",
    city: "",
    zip: "",
    otherDetails: "",
    description: "",
    status: "Open",
    submitted: false,
    isValid: true
  };

  handleChange = name => evt => {
    this.setState({
      [name]: evt.target.value
    });
  };

  handleAddressChange = address => {
    console.log(address);
    this.setState({
      street: address.street,
      state: address.state,
      city: address.city,
      zip: address.zip,
      id: address.id
    });
  };

  handleChangeDates = dates => {
    this.setState({ dates });
  };

  handleAnother = () => {
    this.setState({ submitted: false });
  };

  handleSubmit = () => {
    if (this.getValidation()) {
      this.setState({ isValid: true })
      this.props.dispatch(
        fetchAddList({
          ...this.state,
          startDate: moment(this.state.dates[0].date, "YYYY-MM-DD").toDate(),
          endDate: moment(
            this.state.dates[this.state.dates.length - 1].date,
            "YYYY-MM-DD"
          ).toDate()
        })
      );
      this.setState({
        job: "Optometrist Needed",
        id: "",
        dates: [],
        amount: "",
        startDate: "",
        endDate: "",
        street: "",
        city: "",
        zip: "",
        otherDetails: "",
        description: "",
        submitted: true
      });
    } else {
      this.setState({ isValid: false })
    }
  };

  getValidation = () => {
    if (!!this.state.amount) {
      return true
    } else {
      return false;
    }
  }
  render() {
    const { classes } = this.props;
    console.log(!this.state.isValid && !!this.state.amount)
    return (
      <React.Fragment>
        {!this.state.submitted ? (
          <form className={classes.container} noValidate autoComplete="off">
            <Grid
              container
              className={classes.container}
              spacing={16}
              justify={"center"}
            >
              <Grid item xs={12} sm={6} md={3} className={classes.formItem}>
                <TextField
                  label="Job Title"
                  className={classes.textField}
                  value={this.state.job}
                  onChange={this.handleChange("job")}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} className={classes.formItem}>
                <TextField
                  label="Amount Hourly $"
                  className={classes.textField}
                  value={this.state.amount}
                  margin="normal"
                  variant="outlined"
                  error={!this.state.isValid && !this.state.amount}
                  onChange={this.handleChange("amount")}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} className={classes.formItem}>
                <AddressInput
                  handleAddressChange={this.handleAddressChange}
                  addressId={this.state.id}
                  error={!this.state.isValid && !this.state.street}
                />
              </Grid>
              <Grid item xs={9} sm={9} md={9} className={classes.formItem}>
                <TextField
                  id="filled-multiline-static"
                  label="Job Description"
                  multiline
                  rows="4"
                  rowsMax="4"
                  value={this.state.description}
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                  onChange={this.handleChange("description")}
                />
              </Grid>
              <Grid item xs={12} sm={5} md={5} className={classes.formItem}>
                <TextField
                  id="filled-multiline-static"
                  label="Other Details"
                  multiline
                  rowsMax="1"
                  value={this.state.otherDetails}
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                  onChange={this.handleChange("otherDetails")}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4} className={classes.formItem}>
                <AddDatesDialog
                  dates={this.state.dates}
                  handleChangeDates={this.handleChangeDates}
                />
              </Grid>
              <Grid
                item
                xs={5}
                className={classes.formItem}
                alignItems={"center"}
              >
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={this.handleSubmit}
                >
                  Post
                  {/* This Button uses a Font Icon, see the installation instructions in the docs. */}
                  <Icon className={classes.rightIcon}>send</Icon>
                </Button>
              </Grid>
            </Grid>
          </form>
        ) : (
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={this.handleAnother}
            >
              Submit Another Job
            {/* This Button uses a Font Icon, see the installation instructions in the docs. */}
            </Button>
          )}
      </React.Fragment>
    );
  }
}

Availability.propTypes = {
  classes: PropTypes.object.isRequired
};

export default connect()(withStyles(styles)(Availability));
