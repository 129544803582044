import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { logoutUser } from "../actions/authentication";
import IconButton from "@material-ui/core/IconButton";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Message from "@material-ui/icons/Message";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import { Link } from "react-router-dom";

const styles = theme => ({
  lolStyles: {
    color: "blue",
    "&:active": {
      border: "none",
      background: "blue"
    }
  }
});

class ProfileMenu extends React.Component {
  state = {
    anchorEl: null
  };
  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleLogout = () => {
    this.props.dispatch(logoutUser());
  };
  render() {
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    return (
      <div>
        <IconButton
          aria-owns={open ? "menu-appbar" : null}
          aria-haspopup="true"
          onClick={this.handleMenu}
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          open={open}
          onClose={this.handleClose}
        >
          <Link
            className={this.props.classes.lolStyles}
            style={{ textDecoration: "none" }}
            to="/dashboard/profile"
          >
            <MenuItem onClick={this.handleClose}>
              <span>Profile</span>
            </MenuItem>
          </Link>
          <MenuItem onClick={this.handleLogout}>Logout</MenuItem>
        </Menu>
      </div>
    );
  }
}

export default withStyles(styles)(ProfileMenu);
