import {
  REQUEST_ADD_LIST,
  REQUEST_LIST,
  RECEIVE_ADD_LIST,
  RECEIVE_LIST
} from "../actions/list";

export const list = (
  state = {
    isFetching: false,
    isAddFetching: false,
    items: []
  },
  action
) => {
  switch (action.type) {
    case REQUEST_LIST:
      return {
        ...state,
        isFetching: true
      };
    case RECEIVE_LIST:
      return {
        ...state,
        isFetching: false,
        items: action.payload
      };
    case REQUEST_ADD_LIST:
      return {
        ...state,
        isAddFetching: true
      };
    case RECEIVE_ADD_LIST:
      return {
        ...state,
        isAddFetching: false
      };
    default:
      return state;
  }
};
