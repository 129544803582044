import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import _ from "lodash";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import DatePicker from "react-datepicker";
import moment from "moment";
import Icon from "@material-ui/core/Icon";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import CommentIcon from "@material-ui/icons/Comment";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";

const styles = theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginTop: 16,
    height: 56,
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "80%"
  },
  formItem: {
    textAlign: "center"
  },
  dense: {
    marginTop: 16
  },
  menu: {
    width: 200
  },
  rightIcon: {
    marginLeft: theme.spacing.unit
  },
  button: {
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 15,
    marginBottom: 25,
    width: "50%"
  }
});

class AddDatesDialog extends React.Component {
  state = { open: false, range: false };
  toggleRange = () => {
    this.setState({
      range: !this.state.range,
      startDate: "",
      endDate: "",
      date: ""
    });
  };
  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleAddSingleDate = () => {
    const dates = [...this.props.dates];
    const date = {
      date: this.state.date,
      startDate: null,
      endDate: null,
      id: dates.length
    };
    dates.push(date);
    this.props.handleChangeDates(dates);
  };

  handleAddDateRange = () => {
    const dates = [...this.props.dates];
    const date = {
      date: null,
      id: dates.length,
      startDate: this.state.startDate,
      endDate: this.state.endDate
    };
    dates.push(date);
    this.props.handleChangeDates(dates);
  };

  handleChange = key => ({ target }) => {
    this.setState({ [key]: target.value });
  };

  handleDeleteDate = id => () => {
    const dates = [...this.props.dates];
    _.remove(dates, item => item.id === id);
    this.props.handleChangeDates(dates);
  };
  render() {
    const { classes } = this.props;
    return (
      <div>
        <Button
          variant="outlined"
          color="primary"
          onClick={this.handleClickOpen}
          className={classes.textField}
        >
          {this.props.dates.length > 0 ? "Change Dates" : "Select Dates"}
        </Button>
        {!this.state.open &&
          _.map(this.props.dates, date => (
            <p className={classes.formItem} style={{ width: "50%" }}>
              {date.date}
            </p>
          ))}
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Add Dates</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Add single dates, or date ranges, to list the days your job is
              available. Check must apply for all to not let applicants claim
              only certain days.
            </DialogContentText>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.range}
                    onChange={this.toggleRange}
                  />
                }
                label="Date Range"
              />
            </FormGroup>
            <Grid container>
              {this.state.range ? (
                <React.Fragment>
                  <Grid item xs={5} sm={5} md={5} className={classes.formItem}>
                    <TextField
                      label="Start Date"
                      type="date"
                      className={classes.textField}
                      value={this.state.startDate}
                      InputLabelProps={{
                        shrink: true
                      }}
                      margin="normal"
                      variant="outlined"
                      onChange={this.handleChange("startDate")}
                    />
                  </Grid>
                  <Grid item xs={5} sm={5} md={5} className={classes.formItem}>
                    <TextField
                      label="End Date"
                      type="date"
                      className={classes.textField}
                      value={this.state.endDate}
                      InputLabelProps={{
                        shrink: true
                      }}
                      margin="normal"
                      variant="outlined"
                      onChange={this.handleChange("endDate")}
                    />
                  </Grid>
                  <Grid item xs={2} sm={2} md={2} className={classes.formItem}>
                    <Button
                      variant="fab"
                      color="primary"
                      aria-label="Add"
                      onClick={this.handleAddDateRange}
                      mini
                    >
                      <AddIcon />
                    </Button>
                  </Grid>
                </React.Fragment>
              ) : (
                <Grid item xs={12} sm={12} md={12} className={classes.formItem}>
                  <TextField
                    label="Date"
                    type="date"
                    className={classes.textField}
                    value={this.state.date}
                    InputLabelProps={{
                      shrink: true
                    }}
                    margin="normal"
                    variant="outlined"
                    onChange={this.handleChange("date")}
                  />
                  <Button
                    variant="fab"
                    color="primary"
                    aria-label="Add"
                    onClick={this.handleAddSingleDate}
                    mini
                  >
                    <AddIcon />
                  </Button>
                </Grid>
              )}
            </Grid>

            <Grid item xs={12} sm={6} md={6} className={classes.formItem}>
              <List className={classes.root}>
                <ListItem
                  key={"lol"}
                  role={undefined}
                  dense
                  button
                  onClick={() => {}}
                >
                  <ListItemText primary={`Added Dates:`} />
                </ListItem>
                {this.props.dates.map(value => (
                  <ListItem
                    key={value.id}
                    role={undefined}
                    dense
                    button
                    onClick={() => {}}
                  >
                    <ListItemText
                      primary={
                        value.date
                          ? value.date
                          : `${value.startDate} - ${value.endDate}`
                      }
                    />
                    <ListItemSecondaryAction>
                      <IconButton
                        aria-label="Delete"
                        onClick={this.handleDeleteDate(value.id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={this.handleClose} color="primary">
              Save Dates
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(AddDatesDialog);
