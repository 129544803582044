import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom"
import _ from "lodash";
import { withStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FilledInput from "@material-ui/core/FilledInput";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { fetchAddress, deleteAddress } from "../actions/address";

const styles = theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap"
  },
  formControl: {
    margin: theme.spacing.unit,
    marginTop: 16,
    width: "80%"
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2
  }
});

class AddressInput extends React.Component {
  componentDidMount() {
    this.props.dispatch(fetchAddress());
  }

  handleChange = ({ target }) => {
    const selectedAddress = _.find(this.props.addresses, { id: target.value });
    this.props.handleAddressChange(selectedAddress);
  };
  render() {
    const { classes, dispatch, addresses } = this.props;

    return (
      <FormControl variant="outlined" className={classes.formControl} error={this.props.error}>
        <InputLabel htmlFor="outlined-age-simple">Select Address</InputLabel>
        <Select
          value={this.props.addressId}
          margin="none"
          onChange={this.handleChange}
          input={
            <OutlinedInput
              labelWidth={105}
              name="age"
              id="outlined-age-simple"
            />
          }
        >
          {addresses.map(row => (
            <MenuItem value={row.id}>{row.name}</MenuItem>
          ))}
        </Select>
        {this.props.error && (
          <FormHelperText id="component-error-text">Please Select an address. Go to <Link to="/dashboard/address">Manage Adresses</Link></FormHelperText>
        )}
      </FormControl>
    );
  }
}

AddressInput.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    addresses: state.address.items
  };
};
export default connect(mapStateToProps)(withStyles(styles)(AddressInput));
