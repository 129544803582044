import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
  SIGNUP_FAILURE,
  VERIFY_REQUEST,
  VERIFY_RECEIVE,
  VERIFY_EMAIL_REQUEST,
  VERIFY_EMAIL_SUCCESS,
  VERIFY_EMAIL_FAILURE,
  PASSWORD_RESET_REQUEST,
  PASSWORD_RESET_SUCCESS
} from "../actions/authentication";

export const auth = (
  state = {
    isFetching: false,
    isAuthenticated: false,
    isVerifying: false,
    emailSent: false,
    user: {}
  },
  action
) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        isFetching: true,
        isAuthenticated: false,
        errorMessage: null
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: true,
        user: action.user,
        errorMessage: null
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: false,
        errorMessage: action.message
      };
    case LOGOUT_REQUEST:
      return {
        ...state,
        isFetching: true,
        isAuthenticated: true
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: false
      };
    case VERIFY_REQUEST:
      return {
        ...state,
        isVerifying: true
      };
    case VERIFY_RECEIVE:
      return {
        ...state,
        isVerifying: false
      };
    case PASSWORD_RESET_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case PASSWORD_RESET_SUCCESS:
      return {
        ...state,
        isFetching: false,
        emailSent: true
      };
    default:
      return state;
  }
};

export const signup = (
  state = {
    isFetching: false,
    isCreated: false
  },
  action
) => {
  switch (action.type) {
    case SIGNUP_REQUEST:
      return {
        ...state,
        isFetching: true,
        isCreated: false,
        user: action.creds
      };
    case SIGNUP_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isCreated: true,
        errorMessage: ""
      };
    case SIGNUP_FAILURE:
      return {
        ...state,
        isFetching: false,
        isCreated: false,
        errorMessage: action.message
      };
    default:
      return state;
  }
};

export const verifyEmail = (state = { isFetching: false, isVerified: true }, action) => {
  switch (action.type) {
    case VERIFY_EMAIL_REQUEST:
      return {
        ...state,
        isFetching: true,
        isVerified: true,
      };
    case VERIFY_EMAIL_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isVerified: action.isVerified,
        errorMessage: ""
      };
    case VERIFY_EMAIL_FAILURE:
      return {
        ...state,
        isFetching: false,
        isCreated: false,
        errorMessage: action.message
      };
    default:
      return state;
  }
}
