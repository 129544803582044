import { db } from "../firebase/firebase";
import uuidv4 from "uuid/v4";
import { myFirebase } from "../firebase/firebase";
export const ADDRESS_REQUEST = "ADDRESS_REQUEST";
export const ADDRESS_SUCCESS = "ADDRESS_SUCCESS";
export const ADDRESS_FAILURE = "ADDRESS_FAILURE";

export const ADD_ADDRESS_REQUEST = "ADD_ADDRESS_REQUEST";
export const ADD_ADDRESS_SUCCESS = "ADD_ADDRESS_SUCCESS";
export const ADD_ADDRESS_FAILURE = "ADD_ADDRESS_FAILURE";

export const DELETE_ADDRESS_REQUEST = "DELETE_ADDRESS_REQUEST";
export const DELETE_ADDRESS_SUCCESS = "DELETE_ADDRESS_SUCCESS";
export const DELETE_ADDRESS_FAILURE = "DELETE_ADDRESS_FAILURE";

const requestAddress = () => {
  return {
    type: ADDRESS_REQUEST
  };
};

const receiveAddressSuccess = data => {
  return {
    type: ADDRESS_SUCCESS,
    payload: data
  };
};

const receiveAddressFailure = error => {
  return {
    type: ADDRESS_FAILURE,
    error
  };
};

const requestAddAdress = () => {
  return {
    type: ADD_ADDRESS_REQUEST
  };
};

const receiveAddAddressSuccess = () => {
  return {
    type: ADD_ADDRESS_SUCCESS
  };
};

const receiveAddAddressFailure = error => {
  return {
    type: ADD_ADDRESS_FAILURE,
    error
  };
};

const requestDeleteAddress = id => {
  return {
    type: DELETE_ADDRESS_REQUEST,
    id
  };
};

const receiveDeleteAddressSuccess = id => {
  return {
    type: DELETE_ADDRESS_SUCCESS,
    id
  };
};

const receiveDeleteAddressFailure = (id, error) => {
  return {
    type: DELETE_ADDRESS_FAILURE,
    error,
    id
  };
};

export const deleteAddress = docId => dispatch => {
  const uid = myFirebase.auth().currentUser.uid;
  const docRef = db
    .collection("users")
    .doc(uid)
    .collection("addresses")
    .doc(docId);
  dispatch(requestDeleteAddress());
  docRef
    .delete()
    .then(() => {
      dispatch(receiveDeleteAddressSuccess());
      dispatch(fetchAddress());
    })
    .catch(msg => {
      dispatch(receiveDeleteAddressFailure(msg));
    });
};
export const fetchAddress = () => dispatch => {
  const uid = myFirebase.auth().currentUser.uid;
  const docRef = db
    .collection("users")
    .doc(uid)
    .collection("addresses");
  dispatch(requestAddress());
  docRef
    .get()
    .then(querySnapshot => {
      const markers = [];
      querySnapshot.docs.forEach(doc => {
        markers.push({ ...doc.data(), id: doc.id });
      });
      dispatch(receiveAddressSuccess(markers));
    })
    .catch(msg => {
      dispatch(receiveAddressFailure(msg));
    });
};

export const fetchAddAddress = payload => dispatch => {
  const uid = uuidv4();
  const userId = myFirebase.auth().currentUser.uid;
  dispatch(requestAddAdress());
  const dbRef = db
    .collection("users")
    .doc(userId)
    .collection("addresses");

  dbRef
    .add(payload)
    .then(() => {
      dispatch(receiveAddAddressSuccess());
      console.log("fetch");
      dispatch(fetchAddress(userId));
    })
    .catch(error => {
      dispatch(receiveAddAddressFailure(error));
    });
};
