import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { fetchApplications, updateApplication } from "../actions/applications";
import { updateList, fetchList } from "../actions/list";
import Table from "@material-ui/core/Table";
import Typography from "@material-ui/core/Typography";
import TableBody from "@material-ui/core/TableBody";
import moment from "moment";
import TableCell from "@material-ui/core/TableCell";
import Grid from "@material-ui/core/Grid";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Chip from "@material-ui/core/Chip";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import Icon from "@material-ui/core/Icon";

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto"
  },
  paper: {
    marginTop: 10,
    padding: 20
  },
  button: {
    margin: theme.spacing.unit
  },
  paperRight: {
    marginTop: 10,
    padding: 0,
    height: 385
  },
  action: {
    backgroundColor: "#FF3333",
    color: "#FFF",
    float: "left"
  },
  row: {
    "&:hover": {
      backgroundColor: "#EEE"
    }
  },
  table: {
    minWidth: 700
  },
  actionCell: {
    textAlign: "right"
  },
  addButton: {
    marginTop: 20,
    marginLeft: 20
  }
});

let id = 0;
function createData(name, address, status, actionRequired) {
  id += 1;
  return { id, name, address, status, actionRequired };
}

const rows = [
  createData(
    "Optomotrists in Training",
    "Area 51, Desert, NV",
    "Offer Made",
    true
  ),
  createData(
    "Chattanooga EyeCare",
    "110 Market Street, Chattanooga, TN",
    "Rejected",
    false
  ),
  createData(
    "Ross EyeCare",
    "115 Market Street, Chattanooga, TN",
    "Accepted",
    false
  )
];

class ApplicationsTable extends React.Component {
  state = { row: "" };
  handleSelectRow = row => () => {
    this.props.hideOther(1);
    this.setState({ row: row });
  };

  handleHide = () => {
    this.props.hideOther(null);
    this.setState({ row: "" });
  };

  handleAccept = (appId, jobId) => () => {
    this.props.dispatch(updateApplication(appId, { status: "Accepted" }));
    this.props.dispatch(updateList(jobId, { status: "Filled" }));
    this.props.dispatch(fetchList());
    this.handleHide();
  };

  handleWithdraw = appId => () => {
    this.props.dispatch(
      updateApplication(appId, { status: "Withdrawn", active: false })
    );
    this.handleHide();
  };
  render() {
    const { classes, applications } = this.props;
    const selectedRow = this.state.row;
    console.log(selectedRow);
    return (
      <React.Fragment>
        {!this.state.row ? (
          <Paper className={classes.root}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Business Name</TableCell>
                  <TableCell>Address</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {applications.items.map(row => {
                  return (
                    <TableRow
                      key={row.job.id}
                      className={classes.row}
                      onClick={this.handleSelectRow(row)}
                    >
                      <TableCell component="th" scope="row">
                        {row.job.job}
                      </TableCell>
                      <TableCell>
                        {row.job.street}, {row.job.city}
                      </TableCell>
                      <TableCell>{row.status}</TableCell>
                      <TableCell className={classes.actionCell}>
                        <ChevronRight />
                        {row.actionRequired && (
                          <Chip
                            label="Action Required"
                            className={classes.action}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Paper>
        ) : (
          <Grid container xs={12} spacing={24}>
            <Grid item xs={9}>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={this.handleHide}
              >
                Back
                {/* This Button uses a Font Icon, see the installation instructions in the docs. */}
              </Button>
              <Paper className={classes.paper}>
                <Typography component="h2" variant="h6" gutterBottom>
                  {selectedRow.job.job}
                </Typography>
                <Typography component="p" gutterBottom>
                  <strong>Status</strong> - <Chip label={selectedRow.status} />
                </Typography>
                <p>
                  <strong>Job Description</strong>
                </p>
                <p>{selectedRow.job.description}</p>
                <p>
                  <strong>Requested Start Date</strong>
                </p>
                <p>
                  {moment(selectedRow.job.startDate.timestamp).format(
                    "MM/DD/YYYY"
                  )}
                </p>
                <p>
                  <strong>Address</strong>
                </p>
                <p>
                  {selectedRow.job.street}, {selectedRow.job.city}
                </p>
                <p>
                  <strong>Amount</strong>
                </p>
                <p>${selectedRow.amount}/Hour</p>
              </Paper>
              <Paper className={classes.paper} style={{ marginTop: 24 }}>
                {selectedRow.status === "Offered" && (
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={this.handleAccept(
                      selectedRow.id,
                      selectedRow.jobId
                    )}
                  >
                    Accept
                    {/* This Button uses a Font Icon, see the installation instructions in the docs. */}
                    <Icon
                      className={classes.rightIcon}
                      style={{ marginLeft: 5 }}
                    >
                      Send
                    </Icon>
                  </Button>
                )}
                {selectedRow.status !== "Withdrawn" && (
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                    onClick={this.handleWithdraw(selectedRow.id)}
                  >
                    Withdraw
                    <DeleteIcon className={classes.rightIcon} />
                  </Button>
                )}
              </Paper>
            </Grid>
          </Grid>
        )}
      </React.Fragment>
    );
  }
}

ApplicationsTable.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    applications: state.applications
  };
};

export default connect(mapStateToProps)(withStyles(styles)(ApplicationsTable));
