import React from "react";
import Typography from "@material-ui/core/Typography";
import { connect } from "react-redux";
import { fetchApplications } from "../actions/applications";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";

import ApplicationsTable from "../components/ApplicationsTable";
import JobsTable from "../components/JobsTable";
class Applications extends React.Component {
  state = { show: null };
  componentDidMount() {
    this.props.dispatch(fetchApplications());
  }
  hideOther = num => {
    this.setState({ show: num });
  };
  render() {
    const { list, applications } = this.props;
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Grid className={classes.root} container spacing={24}>
          <Grid container spacing={16} justify="center">
            <Grid item xs={12} sm={12} md={10} lg={10}>
              <div
                style={{
                  padding: "20px",
                  textAlign: "left",
                  marginBottom: "10px"
                }}
              />
              <div
                style={{
                  padding: "20px",
                  textAlign: "left",
                  marginBottom: "10px"
                }}
              >
                <Typography variant="h4">Applications</Typography>
                <hr />
              </div>
              {this.state.show !== 2 &&
                applications.items &&
                applications.items.length > 0 &&
                this.props.userData.isLooking === true && (
                  <React.Fragment>
                    <ApplicationsTable hideOther={this.hideOther} />
                  </React.Fragment>
                )}
              <br />
              {this.state.show !== 1 &&
                list &&
                list.length > 0 &&
                this.props.userData.isLooking === false && (
                  <React.Fragment>
                    <Typography component="h2" variant="h4" gutterBottom>
                      My Jobs
                    </Typography>
                    <JobsTable hideOther={this.hideOther} />
                  </React.Fragment>
                )}
            </Grid>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    list: state.list.items,
    applications: state.applications,
    userData: state.user.user
  };
};

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: "5em"
  },
  card: {
    display: "flex"
  },
  details: {
    display: "flex",
    flexDirection: "column"
  },
  content: {
    flex: "1 0 auto"
  },
  avatar: {
    borderRadius: "100%",
    width: "100%",
    maxHeight: "150px",
    maxWidth: "150px",
    minWidth: "150px"
  },
  uploadButton: {
    whiteSpace: "nowrap",
    display: "block"
  },
  nowrap: {
    whiteSpace: "nowrap"
  },
  fr: {
    textAlign: "right"
  },
  textLeft: {
    textAlign: "left"
  },
  fl: {
    float: "left"
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing.unit,
    paddingBottom: theme.spacing.unit
  },
  playIcon: {
    height: 38,
    width: 38
  },
  info: {
    borderColor: "#2196F3",
    color: "dodgerblue"
  },
  infoHover: {
    background: "#2196F3",
    color: "white"
  },
  sendMsg: {
    paddingTop: 20
  },
  verticalAlign: {
    verticalAlign: "middle"
  },
  nudgeBottom: {
    marginBottom: "5px"
  },
  marginLeft: "5%",
  center: { textAlign: "center", justify: "center" },
  textField: {
    width: 200
  },
  spacing: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit
  }
});

export default withStyles(styles)(connect(mapStateToProps)(Applications));
