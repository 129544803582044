import thunkMiddleware from "redux-thunk";
import { applyMiddleware, createStore } from "redux";
import rootReducer from "./reducers/reducers";
import { verifyAuth } from "./actions/authentication";

export default function configureStore(preloadedState) {
  const store = createStore(
    rootReducer,
    preloadedState,
    applyMiddleware(thunkMiddleware)
  );
  store.dispatch(verifyAuth());
  return store;
}
