import React from "react";
import { Button, Card, CardContent, Grid, Typography } from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";
import { signupUser } from "../actions/authentication";
import { Redirect, Link } from "react-router-dom";
import { connect } from "react-redux";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

class Signup extends React.Component {
  state = {
    email: "",
    password: "",
    confirmPassword: "",
    firstName: "",
    lastName: "",
    submitted: false,
    processed: false,
    invalidLogin: "This email password combo sucks",
    redirect: false,
    demoCode: "",
    showDemoCodeError: false
  };

  componentDidMount() {
    // Custom rule
    ValidatorForm.addValidationRule("isPasswordMatch", value => {
      if (value !== this.state.password) {
        return false;
      }
      return true;
    });
  }

  submitCallback = () => {
    this.setState({ redirect: true });
  };
  handleSubmit = () => {
    if(this.state.demoCode === "pgg") {
    this.setState({ submitted: true, processed: true }, () => {
      setTimeout(() => this.setState({ submitted: false }), 5000);
      this.props.dispatch(
        signupUser(
          {
            email: this.state.email,
            password: this.state.password,
            displayName: `${this.state.firstName} ${this.state.lastName}`,
            firstName: this.state.firstName,
            lastName: this.state.lastName
          },
          this.submitCallback
        )
      );
      return this.props.isAuthenticated;
    });
  } else {
    this.setState({showDemoCodeError: true})
  }
  };

  handleEmailChange = event => {
    const email = event.target.value;
    this.setState({ email, processed: false });
  };

  handleEmailBlur = event => {
    this.refs[event.target.name].validate(event.target.value);
  };

  handlePasswordChange = event => {
    const password = event.target.value;
    this.setState({ password, processed: false });
  };

  handlePasswordBlur = event => {
    // one lowercase letter
    // one uppercase letter
    // one numeric character
    // at least 8 chars long
    this.refs[event.target.name].validate(event.target.value);
  };

  handleConfirmPasswordChange = event => {
    const confirmPassword = event.target.value;
    this.setState({ confirmPassword, processed: false });
  };

  handleConfirmPasswordBlur = event => {
    this.refs[event.target.name].validate(event.target.value);
  };

  handleFirstNameChange = event => {
    const firstName = event.target.value;
    const pattern = /^([^0-9!@#$%^&*(){}:;<>~?|._+=]*)$/;
    const isValid = pattern.test(firstName);
    if (isValid) {
      this.setState({ firstName });
    }
  };

  handleFirstNameBlur = event => {
    if (event.target.value) {
      this.refs[event.target.name].validate(event.target.value);
    }
  };

  handleLastNameChange = event => {
    const lastName = event.target.value;
    const pattern = /^([^0-9!@#$%^&*()_+=]*)$/;
    const isValid = pattern.test(lastName);
    if (isValid) {
      this.setState({ lastName });
    }
  };

  handleLastNameBlur = event => {
    if (event.target.value) {
      this.refs[event.target.name].validate(event.target.value);
    }
  };

  handleDemoCodeChange = event => {
      this.refs[event.target.name].validate(event.target.value);
      this.setState({showDemoCodeError: false, demoCode: event.target.value});

  }

  render() {
    const { classes } = this.props;
    const { from } = this.props.location.state || { from: { pathname: "/" } };
    const {
      email,
      password,
      confirmPassword,
      submitted,
      firstName,
      lastName
    } = this.state;
    if (this.props.isAuthenticated) {
      return <Redirect to={from} />;
    } else {
      return (
        <div>
          <Grid
            container
            alignItems="center"
            justify="center"
            className={classes.fullHeight + " " + classes.gridBackground}
          >
            <Grid item sm={4} md={3} xs={8}>
              <Card
                style={styles}
                className={classes.card + " " + classes.textCenter}
              >
                <CardContent>
                  <Typography
                    variant="headline"
                    className={classes.headline}
                    gutterBottom
                  >
                    Sign up
                  </Typography>
                  <ValidatorForm
                    ref="form"
                    onSubmit={this.handleSubmit}
                    instantValidate={false}
                    onError={errors => console.log(errors)}
                  >
                    <TextValidator
                      label="First Name"
                      ref="firstName"
                      name="firstName"
                      className={classes.fl}
                      onChange={this.handleFirstNameChange}
                      onBlur={this.handleFirstNameBlur}
                      value={firstName}
                      validators={["required"]}
                      errorMessages={["First name is required", ""]}
                    />
                    <TextValidator
                      label="Last Name"
                      ref="lastName"
                      name="lastName"
                      className={classes.fr}
                      onChange={this.handleLastNameChange}
                      onBlur={this.handleLastNameBlur}
                      value={lastName}
                      validators={["required"]}
                      errorMessages={["Last name is required"]}
                    />
                    <TextValidator
                      label="Email"
                      ref="email"
                      name="email"
                      onChange={this.handleEmailChange}
                      onBlur={this.handleEmailBlur}
                      fullWidth
                      value={email}
                      validators={["required", "isEmail"]}
                      errorMessages={[
                        "Email is required",
                        "Email is not valid"
                      ]}
                    />
                    <TextValidator
                      label="Password"
                      name="password"
                      ref="password"
                      type="password"
                      onChange={this.handlePasswordChange}
                      onBlur={this.handlePasswordBlur}
                      fullWidth
                      value={password}
                      validators={[
                        "required",
                        "matchRegexp:^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
                      ]}
                      errorMessages={[
                        "Password is required",
                        "Password must contain at least one upper case letter, one lower case letter, one number, one special character and be at least 8 characters long"
                      ]}
                    />
                    <TextValidator
                      label="Confirm Password"
                      name="confirmPassword"
                      ref="confirmPassword"
                      type="password"
                      onChange={this.handleConfirmPasswordChange}
                      onBlur={this.handleConfirmPasswordBlur}
                      fullWidth
                      value={confirmPassword}
                      validators={["isPasswordMatch", "required"]}
                      errorMessages={[
                        "Passwords must match",
                        "Confirm password is required"
                      ]}
                    />
                    <TextValidator
                      label="Demo Code"
                      ref="demoCode"
                      name="demoCode"
                      className={classes.fl}
                      onChange={this.handleDemoCodeChange}
                      value={this.state.demoCode}
                      validators={["required"]}
                      errorMessages={["Enter your demo code"]}
                    />
                     <br />
                    <br />
                    {this.state.showDemoCodeError && <Typography>Invalid demo code</Typography>}
              
                    <br />
                    <br />
                    <Button
                      variant="contained"
                      raised="true"
                      className={
                        classes.button + " " + classes.label + " " + classes.fl
                      }
                      classes={{
                        label: classes.label // class name, e.g. `classes-nesting-label-x`
                      }}
                      styles={styles.button}
                      type="submit"
                      disabled={submitted && this.props.isSignupFetching}
                    >
                      SIGN UP
                    </Button>
                  </ValidatorForm>
                  {!this.props.isVerified && (
                    <Typography
                      variant="body1"
                      component="p"
                      className={`${classes.nudgeTop} ${classes.red}`}
                    >
                      A user with this email already exists
                    </Typography>
                  )}
                  <br />
                  <Grid container spacing={24}>
                    <Grid item xs={6}>
                      <Typography gutterBottom variant="body2">
                        <Link
                          to="/login"
                          className={classes.fl + " " + classes.link}
                        >
                          Already have an account?
                        </Link>
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          {this.state.redirect && <Redirect to="/login" />}
        </div>
      );
    }
  }
}

const styles = {
  button: {
    paddingLeft: "20px",
    paddingRight: "20px",
    width: "100%",
    background:
      "linear-gradient(to right top, #566866, #41959E, #77ACA2, #9DBEBB, #F4E9CD)",
    color: "white",
    marginRight: "5px"
  },
  gridBackground: {
    background:
      "linear-gradient(to right top, #031926, #41959E, #77ACA2, #9DBEBB, #F4E9CD)"
    // "linear-gradient(to right top, #00c9b7, #00c8d5, #00c4ed, #3bbefb, #7ab6ff)"
  },
  fullHeight: {
    height: "100vh"
  },
  margin: 15,
  card: {
    minWidth: 450,
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 15,
    paddingRight: 15
  },
  title: {
    marginBottom: 16,
    fontSize: 14
  },
  headline: {
    color: "#9E9E9E"
  },
  pos: {
    marginBottom: 12
  },
  textCenter: {
    textAlign: "center"
  },
  textField: {
    flexBasis: 200
  },
  media: {
    height: 0,
    paddingTop: "56.25%" // 16:9
  },
  label: {
    textTransform: "capitalize"
  },
  fl: {
    float: "left"
  },
  fr: {
    float: "right"
  },
  nudgeTop: {
    paddingTop: "45px"
  },
  nowrap: {
    whiteSpace: "nowrap"
  },
  link: {
    textDecoration: "none",
    color: "rgb(94, 191, 240)"
  },
  white: {
    color: "white"
  },
  red: {
    color: "red"
  }
};

function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    isCreated: state.signup.isCreated,
    isSignupFetching: state.signup.isFetching,
    isVerified: state.verifyEmail.isVerified
  };
}
export default withStyles(styles)(connect(mapStateToProps)(Signup));
