import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { connect } from "react-redux";
import { updateProfileData, currentUserData } from "../../actions/user";

class AlertDialog extends React.Component {
  state = { open: true };

  handleClose = () => {
    this.setState({ open: false });
    this.props.dispatch(currentUserData());
  };

  handleChoice = choice => () => {
    this.props.dispatch(
      updateProfileData(
        this.props.userData.uid,
        { isLooking: choice },
        this.handleClose
      )
    );
  };

  render() {
    return (
      <div>
        <Dialog
          open={this.state.open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Please Choose Your Account Type"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Welcome to Notifeyes! Please select the option that describes what
              you are looking for. You can change your choice by editing your
              profile.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleChoice(false)} color="primary">
              I Am Looking to Hire
            </Button>
            <Button onClick={this.handleChoice(true)} color="primary" autoFocus>
              I Am Looking for a Job
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    userData: state.user.user
  };
};

export default connect(mapStateToProps)(AlertDialog);
