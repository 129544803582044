import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const config = {
  apiKey: "AIzaSyAWfZ7eCOSbpRQh_qVLcxnwPQL7hALAyEo",
  authDomain: "notifeyes1.firebaseapp.com",
  databaseURL: "https://notifeyes1.firebaseio.com",
  projectId: "notifeyes1",
  storageBucket: "notifeyes1.appspot.com",
  messagingSenderId: "673233966908"
};

export const myFirebase = firebase.initializeApp(config);
const baseDb = myFirebase.firestore();
baseDb.settings({ timestampsInSnapshots: true });
export const db = baseDb;
