import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import DatePicker from "react-datepicker";
import moment from "moment";
import TextField from "@material-ui/core/TextField";
import Icon from "@material-ui/core/Icon";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

const styles = theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "80%"
  },
  formItem: {
    textAlign: "center"
  },
  dense: {
    marginTop: 16
  },
  menu: {
    width: 200
  },
  rightIcon: {
    marginLeft: theme.spacing.unit
  },
  button: {
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 15,
    marginBottom: 25,
    width: "50%"
  }
});

class OpenPositions extends React.Component {
  state = {
    job: "Optometrist",
    amount: "",
    startDate: "",
    endDate: "",
    street: "",
    city: "",
    zip: "",
    notes: ""
  };

  handleChange = (name, evt) => {
    this.setState({
      [name]: evt.target.value
    });
  };

  render() {
    const { classes } = this.props;

    return (
      <form className={classes.container} noValidate autoComplete="off">
        <Grid container>
          <Grid item xs={12} sm={6} md={3} className={classes.formItem}>
            <TextField
              label="Job Title"
              className={classes.textField}
              value={"Optometrist"}
              disabled={true}
              margin="normal"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} className={classes.formItem}>
            <TextField
              label="Amount Hourly $"
              className={classes.textField}
              value={this.state.amount}
              margin="normal"
              variant="outlined"
              onChange={evt => this.handleChange("amount", evt)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} className={classes.formItem}>
            <TextField
              label="Start Date"
              type="date"
              className={classes.textField}
              value={this.state.startDate}
              InputLabelProps={{
                shrink: true
              }}
              margin="normal"
              variant="outlined"
              onChange={evt => this.handleChange("startDate", evt)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} className={classes.formItem}>
            <TextField
              label="End Date"
              type="date"
              className={classes.textField}
              value={this.state.endDate}
              InputLabelProps={{
                shrink: true
              }}
              margin="normal"
              variant="outlined"
              onChange={evt => this.handleChange("endDate", evt)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} className={classes.formItem}>
            <TextField
              label="Street"
              className={classes.textField}
              value={this.state.street}
              margin="normal"
              variant="outlined"
              onChange={evt => this.handleChange("street", evt)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} className={classes.formItem}>
            <TextField
              label="City"
              className={classes.textField}
              value={this.state.city}
              margin="normal"
              variant="outlined"
              onChange={evt => this.handleChange("city", evt)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} className={classes.formItem}>
            <TextField
              label="Zip"
              className={classes.textField}
              value={this.state.stree}
              margin="normal"
              variant="outlined"
              onChange={evt => this.handleChange("zip", evt)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} className={classes.formItem}>
            <TextField
              id="filled-multiline-static"
              label="Additional Notes"
              multiline
              rowsMax="4"
              value={this.state.notes}
              className={classes.textField}
              margin="normal"
              variant="outlined"
              onChange={evt => this.handleChange("notes", evt)}
            />
          </Grid>
          <Grid item xs={12} className={classes.formItem}>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
            >
              Post
              {/* This Button uses a Font Icon, see the installation instructions in the docs. */}
              <Icon className={classes.rightIcon}>send</Icon>
            </Button>
          </Grid>
        </Grid>
      </form>
    );
  }
}

OpenPositions.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(OpenPositions);
