import { myFirebase } from "../firebase/firebase";
import { db } from "../firebase/firebase";
import axios from "axios";

export const LICENSE_REQUEST = "LICENSE_REQUEST";
export const LICENSE_SUCCESS = "LICENSE_SUCCESS";
export const LICENSE_FAILURE = "LICENSE_FAILURE";

export const SUBMIT_REQUEST = "SUBMIT_REQUEST";
export const SUBMIT_SUCCESS = "SUBMIT_SUCCESS";
export const SUBMIT_FAILURE = "SUBMIT_FAILURE";

const requestLicense = () => {
  return {
    type: LICENSE_REQUEST
  };
};

const requestSubmit = () => {
  return {
    type: SUBMIT_REQUEST
  }
}

const receiveSubmit = () => {
  return {
    type: SUBMIT_SUCCESS
  }
}

const failureSubmit = () => {
  return {
    type: SUBMIT_FAILURE
  }
}

const receiveLicenseSuccess = data => {
  return {
    type: LICENSE_SUCCESS,
    results: data.results,
    count: data.result_count
  };
};

const receiveLicenseFailure = error => {
  return {
    type: LICENSE_FAILURE,
    error
  };
};

export const fetchLicense = data => dispatch => {
  dispatch(requestLicense());
  axios
    .get("/api/v1/verifyLicense", {
      params: data
    })
    .then(json => {
      dispatch(receiveLicenseSuccess(json.data));
    })
    .catch(error => {
      dispatch(receiveLicenseFailure(error));
    });
};

export const submitLicense = data => dispatch => {
  const uid = myFirebase.auth().currentUser.uid;
  const docRef = db.collection("users").doc(uid);
  const profileData = {npi: data, npiVerified: true}
  dispatch(requestSubmit());
  docRef
    .set(profileData, {
      merge: true
    })
    .then(() => {
      dispatch(receiveSubmit());
    })
    .catch(error => {
      console.log(error);
    });
}

