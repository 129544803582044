import React from "react";
import BrowseTable from "../components/BrowseTable";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import moment from "moment";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import Button from "@material-ui/core/Button";
import { fetchJobs } from "../actions/browse";
import Grid from "@material-ui/core/Grid";

const styles = theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap"
  },
  margin: {
    margin: theme.spacing.unit
  },
  textField: {
    flexBasis: 200
  }
});

class Browse extends React.Component {
  state = { show: true, startDate: moment().format("YYYY-MM-DD") };
  componentDidMount() {
    this.handleSearch();
  }
  handleSearch = () => {
    this.props.dispatch(
      fetchJobs({ startDate: new Date(this.state.startDate) })
    );
  };
  hideSearch = () => {
    this.setState({ show: false });
  };
  showSearch = () => {
    this.setState({ show: true });
  };
  handleChange = key => ({ target }) => {
    this.setState({ [key]: target.value });
  };
  render() {
    const { classes, jobs } = this.props;
    return (
      <React.Fragment>
        <Grid className={classes.root} container spacing={24}>
          <Grid container spacing={16} justify="center">
            <Grid item xs={12} sm={12} md={10} lg={10}>
              <div
                style={{
                  padding: "20px",
                  textAlign: "left",
                  marginBottom: "10px"
                }}
              >
                <Typography variant="h4">Browse</Typography>
                <hr />
              </div>
              {this.state.show && (
                <React.Fragment>
                  <InputLabel className={classes.margin} htmlFor="account-type">
                    Start Date:{" "}
                  </InputLabel>
                  <TextField
                    type="date"
                    value={this.state.startDate}
                    className={classes.margin}
                    onput={<Input id="date" />}
                    onChange={this.handleChange("startDate")}
                    margin="normal"
                  />
                  <Button
                    variant="button"
                    color="primary"
                    aria-label="Add"
                    className={classes.addButton}
                    onClick={this.handleSearch}
                  >
                    <SearchIcon />
                  </Button>
                </React.Fragment>
              )}
              <BrowseTable
                jobs={this.props.jobs}
                hideSearch={this.hideSearch}
                showSearch={this.showSearch}
              />
            </Grid>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => {
  return {
    jobs: state.jobs.items
  };
};
export default connect(mapStateToProps)(withStyles(styles)(Browse));
