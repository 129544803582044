import axios from "axios";
import { db } from "../firebase/firebase";
import { myFirebase } from "../firebase/firebase";

export const REQUEST_ADD_APPLY = "REQUEST_ADD_APPLY";
export const RECEIVE_ADD_APPLY = "RECEIVE_ADD_APPLY";
export const REQUEST_UPDATE = "REQUEST_UPDATE";
export const RECEIVE_UPDATE = "RECEIVE_UPDATE";
export const REQUEST_APPLICATIONS = "REQUEST_APPLICATIONS";
export const RECEIVE_APPLICATIONS = "RECEIVE_APPLICATIONS";
export const REQUEST_JOB_APPLICATIONS = "REQUEST_JOB_APPLICATIONS";
export const RECEIVE_JOB_APPLICATIONS = "RECEIVE_JOB_APPLICTIONS";

const requestAddApply = () => {
  return {
    type: REQUEST_ADD_APPLY
  };
};

const receiveAddApply = () => {
  return {
    type: RECEIVE_ADD_APPLY
  };
};

const requestUpdate = () => {
  return {
    type: REQUEST_UPDATE
  };
};

const receiveUpdate = () => {
  return {
    type: RECEIVE_UPDATE
  };
};

const requestApplications = () => {
  return {
    type: REQUEST_APPLICATIONS
  };
};

const receiveApplications = data => {
  return {
    type: RECEIVE_APPLICATIONS,
    payload: data
  };
};

const requestJobApplications = () => {
  return {
    type: REQUEST_JOB_APPLICATIONS
  };
};

const receiveJobApplications = data => {
  return {
    type: RECEIVE_JOB_APPLICATIONS,
    payload: data
  };
};

export const addApply = jobId => (dispatch, getState) => {
  const docRef = db.collection("applications");
  const userId = myFirebase.auth().currentUser.uid;
  const { firstName, lastName } = getState().user.user;
  const payload = {
    jobId,
    active: true,
    status: "Applied",
    userId,
    firstName,
    lastName
  };
  dispatch(requestAddApply());
  docRef.add({ ...payload }).then(() => {
    dispatch(receiveAddApply());
    dispatch(fetchApplications());
  });
};

export const updateApplication = (id, payload) => dispatch => {
  const docRef = db.collection("applications").doc(id);
  dispatch(requestUpdate());
  docRef.update({ ...payload }).then(() => {
    dispatch(receiveUpdate());
    dispatch(fetchApplications());
  });
};

export const fetchApplications = () => dispatch => {
  const docRef = db.collection("applications");
  const docRef2 = db.collection("jobs");
  const userId = myFirebase.auth().currentUser.uid;
  const data1 = [];
  const finalData = [];
  dispatch(requestApplications());
  docRef
    .where("userId", "==", userId)
    .where("active", "==", true)
    .get()
    .then(querySnapshot => {
      querySnapshot.forEach(doc => {
        data1.push({ ...doc.data(), id: doc.id });
      });
      if (data1.length > 0) {
        data1.forEach((doc, index) => {
          docRef2
            .doc(doc.jobId)
            .get()
            .then(innerDoc => {
              finalData.push({
                ...doc,
                job: { ...innerDoc.data(), id: innerDoc.id }
              });
              if (index === data1.length - 1) {
                dispatch(receiveApplications(finalData));
              }
            });
        });
      } else {
        dispatch(receiveApplications(finalData));
      }
    });
};

export const fetchApplicationsToMyJobs = jobsIds => dispatch => {
  const finalData = {};
  const docRef = db.collection("applications");
  dispatch(receiveJobApplications);
  jobsIds.forEach((jobId, index) => {
    docRef
      .where("jobId", "==", jobId)
      .get()
      .then(querySnapshot => {
        const data = [];
        querySnapshot.forEach(doc => {
          data.push({ ...doc.data(), id: doc.id });
        });
        finalData[jobId] = data;
        if (index === jobsIds.length - 1) {
          dispatch(receiveJobApplications(finalData));
        }
      });
  });
};
