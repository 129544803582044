import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import Table from "@material-ui/core/Table";
import _ from "lodash";
import queryString from "query-string";
import Typography from "@material-ui/core/Typography";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Grid from "@material-ui/core/Grid";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Chip from "@material-ui/core/Chip";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import SearchIcon from "@material-ui/icons/Search";
import Icon from "@material-ui/core/Icon";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import { addApply } from "../actions/applications";

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto"
  },
  paper: {
    marginTop: 10,
    padding: 20
  },
  button: {
    margin: theme.spacing.unit
  },
  paperRight: {
    marginTop: 10,
    padding: 0,
    height: 385
  },
  action: {
    backgroundColor: "#FF3333",
    color: "#FFF",
    float: "left"
  },
  row: {
    "&:hover": {
      backgroundColor: "#EEE"
    }
  },
  table: {
    minWidth: 700
  },
  actionCell: {
    textAlign: "right"
  },
  addButton: {
    marginTop: 20,
    marginLeft: 20
  }
});

let id = 0;
function createData(name, address, date, days, distance) {
  id += 1;
  return { id, name, address, date, days, distance };
}

const rows = [
  createData(
    "Optomotrists in Training",
    "110 Riverfront Drive, Chattanooga, TN",
    "3/2/2019",
    5,
    2
  ),
  createData(
    "Chattanooga EyeCare",
    "110 Market Street, Chattanooga, TN",
    "3/2/2019",
    5,
    5.2
  ),
  createData(
    "Ross EyeCare",
    "115 Market Street, Chattanooga, TN",
    "3/3/2019",
    "Open Ended",
    5.3
  )
];

class BrowseTable extends React.Component {
  state = { show: true, row: "" };
  handleShowRow = row => () => {
    this.setState({ row: row });
  };
  handleHideRow = () => {
    this.setState({ row: "" });
  };

  handleApply = () => {
    this.props.dispatch(addApply(this.state.row.id));
    this.handleHideRow();
  };
  render() {
    const { classes } = this.props;
    const inlineRow = this.state.row;
    return (
      <React.Fragment>
        <Paper className={classes.root}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell>City</TableCell>
                <TableCell>Start Date</TableCell>
                <TableCell>Number of Days</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {this.props.jobs.map(row => {
                return (
                  <TableRow
                    key={row.id}
                    className={classes.row}
                    onClick={this.handleShowRow(row)}
                  >
                    <TableCell component="th" scope="row">
                      <strong>{row.job}</strong>
                    </TableCell>
                    <TableCell>{row.city}</TableCell>
                    <TableCell>
                      {new Date(
                        row.startDate.seconds * 1000
                      ).toLocaleDateString()}
                    </TableCell>
                    <TableCell>{row.dates.length}</TableCell>
                    <TableCell className={classes.actionCell}>
                      <ChevronRight />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Paper>
        {inlineRow && (
          <Dialog
            open={inlineRow}
            onClose={this.handleHideRow}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">{inlineRow.job}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                <Typography component="p" gutterBottom>
                  <strong>Status</strong> - <Chip label="Open" />
                </Typography>
                <p>
                  <strong>Job Description</strong>
                </p>
                <p>{inlineRow.description}</p>
                <p>
                  <strong>Working Dates</strong>
                </p>
                <p>
                  {_.map(inlineRow.dates, date => (
                    <p>{new Date(date.date).toDateString()}</p>
                  ))}{" "}
                </p>
                <p>
                  <strong>Address</strong>
                </p>
                <p><a target="_blank" href={`https://www.google.com/maps/search/?api=1&query=${inlineRow.street + " " + inlineRow.city + ", " + inlineRow.zip}
`}>{`${inlineRow.street}, ${inlineRow.city} ${
                    inlineRow.zip
                    }`}</a></p>
                <p>
                  <strong>Pay:</strong> ${inlineRow.amount}/hr
                </p>
                <p>
                  <strong>Total Hours:</strong> {inlineRow.dates.length * 8}
                </p>
                <p>
                  <strong>Total Compensation:</strong> $
                  {parseInt(inlineRow.amount, 10) *
                    (inlineRow.dates.length * 8)}
                </p>
                <p>
                  <strong>Other Details:</strong> {inlineRow.otherDetails}
                </p>
                <p>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={this.handleApply}
                    disabled={!this.props.userData.npiVerified}
                  >
                    {this.props.userData.npiVerified
                      ? "Apply"
                      : "Please Verify NPI Number"}
                    {/* This Button uses a Font Icon, see the installation instructions in the docs. */}
                    <Icon
                      className={classes.rightIcon}
                      style={{ marginLeft: 5 }}
                    >
                      Send
                    </Icon>
                  </Button>
                </p>
              </DialogContentText>
            </DialogContent>
          </Dialog>
        )}
      </React.Fragment>
    );
  }
}

BrowseTable.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    userData: state.user.user
  };
};

export default connect(mapStateToProps)(withStyles(styles)(BrowseTable));
