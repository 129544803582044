import {
  LICENSE_REQUEST,
  LICENSE_SUCCESS,
  LICENSE_FAILURE
} from "../actions/licenseVerification";

export const verifyLicense = (
  state = {
    isFetching: false,
    results: [],
    count: null
  },
  action
) => {
  switch (action.type) {
    case LICENSE_REQUEST:
      return {
        ...state,
        isFetching: true,
        results: [],
        count: null
      };
    case LICENSE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        results: action.results,
        count: action.count
      };
    case LICENSE_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.error
      };
    default:
      return state;
  }
};
