import React, { Component } from "react";
import { connect } from "react-redux";
import "./App.css";
import Login from "../components/Login";
import Signup from "../components/Signup";
import Landing from "../components/Landing";
import PrivateRoute from "../components/PrivateRoute";
import Dashboard from "./Dashboard";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";

class App extends Component {
  render() {
    return (
      <Router>
        <React.Fragment>
          <Route
            path="/login"
            render={props => (
              <Login {...props} isFetching={this.props.isFetching} />
            )}
          />
          <Route path="/signup" render={props => <Signup {...props} />} />
          <Route path="/comingsoon" render={props => <Landing {...props} />} />
          <PrivateRoute
            path="/dashboard"
            component={Dashboard}
            isAuthenticated={this.props.isAuthenticated}
            isVerifying={this.props.isVerifying}
          />
          <Route
            exact
            path="/"
            render={() =>
              this.props.isAuthenticated ? (
                <Redirect to="/dashboard" />
              ) : (
                <Redirect to="/comingsoon" />
              )
            }
          />
        </React.Fragment>
      </Router>
    );
  }
}

function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    isVerifying: state.auth.isVerifying,
    isFetching: state.auth.isFetching
  };
}
export default connect(mapStateToProps)(App);
