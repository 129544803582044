import React from "react";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

const styles = theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200
  },
  dense: {
    marginTop: 19
  },
  menu: {
    width: 200
  }
});

class TextFields extends React.Component {
  static getDerivedStateFromProps(props) {
    const license = props.selectedLicense;
    const address = props.selectedLicense.addresses[0];
    return {
      firstName: license.basic.first_name,
      lastName: license.basic.last_name,
      credential: license.basic.credential,
      street: address.address_1,
      zip: address.postal_code,
      city: address.city,
      state: address.state,
      phone: address.telephone_number
    };
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  render() {
    const { classes } = this.props;

    return (
      <form className={classes.container}>
        <TextField
          id="first-name"
          label="First Name"
          className={classes.textField}
          value={this.state.firstName}
          onChange={this.handleChange("firstName")}
          margin="normal"
        />
        <TextField
          id="last-name"
          label="Last Name"
          className={classes.textField}
          value={this.state.lastName}
          onChange={this.handleChange("lastName")}
          margin="normal"
        />
        <TextField
          id="street"
          label="Street Address"
          className={classes.textField}
          value={this.state.street}
          onChange={this.handleChange("street")}
          margin="normal"
        />
        <TextField
          id="city"
          label="City"
          className={classes.textField}
          value={this.state.city}
          onChange={this.handleChange("city")}
          margin="normal"
        />
        <TextField
          id="state"
          label="State"
          className={classes.textField}
          value={this.state.state}
          onChange={this.handleChange("state")}
          margin="normal"
        />
        <TextField
          id="zip"
          label="Postal Code"
          className={classes.textField}
          value={this.state.zip}
          onChange={this.handleChange("zip")}
          margin="normal"
        />
        <TextField
          id="phone"
          label="Phone Number"
          className={classes.textField}
          value={this.state.phone}
          onChange={this.handleChange("phone")}
          margin="normal"
        />
      </form>
    );
  }
}

export default withStyles(styles)(TextFields);
