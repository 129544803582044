import {
  ADDRESS_REQUEST,
  ADD_ADDRESS_REQUEST,
  DELETE_ADDRESS_REQUEST,
  ADDRESS_SUCCESS,
  ADD_ADDRESS_SUCCESS,
  DELETE_ADDRESS_SUCCESS
} from "../actions/address";

export const address = (
  state = {
    isFetching: false,
    isAdding: false,
    isDeleting: false,
    items: []
  },
  action
) => {
  switch (action.type) {
    case ADDRESS_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case ADD_ADDRESS_REQUEST:
      return {
        ...state,
        isAdding: true
      };
    case DELETE_ADDRESS_REQUEST:
      return {
        ...state,
        isDeleting: true
      };
    case ADDRESS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        items: action.payload
      };
    case ADD_ADDRESS_SUCCESS:
      return {
        ...state,
        isAdding: false
      };
    case DELETE_ADDRESS_SUCCESS:
      return {
        ...state,
        isDeleting: false
      };
    default:
      return state;
  }
};
