import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import LocationOn from "@material-ui/icons/VerifiedUser";
import Message from "@material-ui/icons/Message";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Button from "@material-ui/core/Button";
import Edit from "@material-ui/icons/Edit";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: "2em"
  },
  card: {
    display: "flex"
  },
  details: {
    display: "flex",
    flexDirection: "column"
  },
  content: {
    flex: "1 0 auto"
  },
  avatar: {
    borderRadius: "100%",
    width: "100%",
    maxHeight: "150px",
    maxWidth: "150px",
    minWidth: "150px"
  },
  uploadButton: {
    whiteSpace: "nowrap",
    display: "block"
  },
  nowrap: {
    whiteSpace: "nowrap"
  },
  fr: {
    textAlign: "right"
  },
  fl: {
    marginRight: "100%"
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing.unit,
    paddingBottom: theme.spacing.unit
  },
  playIcon: {
    height: 38,
    width: 38
  },
  info: {
    borderColor: "#2196F3",
    color: "dodgerblue"
  },
  infoHover: {
    background: "#2196F3",
    color: "white"
  },
  sendMsg: {
    paddingTop: 20
  },
  verticalAlign: {
    verticalAlign: "middle"
  },
  nudgeBottomTitle: {
    marginBottom: "10px"
  },
  nudgeBottomSection: {
    marginBottom: "10em"
  },
  nudgeLeftSection: {
    marginRight: "10em"
  },
  marginLeft: "5%",
  center: { textAlign: "center", justify: "center" }
});

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

class Profile extends React.Component {
  state = {
    value: 0
  };

  handleTabChange = (event, value) => {
    this.setState({ value });
  };

  render() {
    const { classes } = this.props;
    const props = this.props;
    console.log(props);
    const { firstName, lastName, description } = props.userData;

    return (
      <Grid className={classes.root} container spacing={24}>
        <Grid container spacing={16} justify="center">
          <Grid item xs={12} sm={12} md={10} lg={10}>
            <div
              style={{
                padding: "20px",
                textAlign: "left",
                marginBottom: "10px"
              }}
            >
              <Typography variant="h4">Profile</Typography>
              <hr />
            </div>
          </Grid>
          <Grid container spacing={8} justify="center">
            <Grid item xs={12}>
              <div className={`${classes.fr} ${classes.nudgeLeftSection}`}>
                <Link exact={"true"} to="/dashboard/profile/edit">
                  <Button
                    aria-haspopup="true"
                    onClick={this.handleMenu}
                    color="primary"
                    variant="fab"
                  >
                    <Edit />
                  </Button>
                </Link>
              </div>
              <div
                className={`${classes.center} ${classes.nudgeBottomSection}`}
              >
                <img
                  alt="profile"
                  className={classes.avatar}
                  src="/assets/profile-picture-placeholder.png"
                />
                <br />
                <Typography className={classes.nowrap} variant="headline">
                  {firstName ? firstName : ""} {lastName ? lastName : ""}
                </Typography>
                <Typography
                  className={`${classes.nowrap} ${classes.nudgeBottomTitle}`}
                  variant="title"
                >
                  O.D.
                </Typography>
                {this.props.userData.npiVerified && (
                  <Typography className={classes.nowrap} variant="subheading">
                    {/* Add location prop */}
                    <LocationOn className={classes.verticalAlign} /> NPI
                    Verified
                  </Typography>
                )}
                <br />
                <Grid container spacing={16} justify="center">
                  <Grid item xs={12} sm={12} md={8} lg={8}>
                    <Paper className={`${classes.paper}`}>{description}</Paper>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

Profile.propTypes = {
  classes: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    userData: state.user.user,
    authData: state.auth
  };
}

export default withStyles(styles)(connect(mapStateToProps)(Profile));
