import {
  USER_REQUEST,
  USER_SUCCESS,
  USER_FAILURE,
  PERSONAL_INFO_REQUEST,
  PERSONAL_INFO_SUCCESS,
  UPDATE_PERSONAL_INFO_REQUEST,
  UPDATE_PERSONAL_INFO_SUCCESS
} from "../actions/user";

export const user = (
  state = {
    isFetching: false,
    user: {}
  },
  action
) => {
  switch (action.type) {
    case USER_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case USER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        user: action.user
      };
    case USER_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.message
      };
    default:
      return state;
  }
};

export const personalInfo = (
  state = {
    info: {}
  },
  action
) => {
  switch (action.type) {
    case PERSONAL_INFO_REQUEST:
      return {
        isFetching: true
      };
    case PERSONAL_INFO_SUCCESS:
      return {
        isFetching: false
      };
    case UPDATE_PERSONAL_INFO_REQUEST:
      return {
        isFetching: true
      };
    case UPDATE_PERSONAL_INFO_SUCCESS:
      return {
        isFetching: false
      };
    default:
      return state;
  }
};
