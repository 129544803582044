import React from "react";
import { Link } from "react-router-dom";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import AddLocation from "@material-ui/icons/AddLocation";
import LocationOff from "@material-ui/icons/LocationOff";

import VerifyUser from "@material-ui/icons/VerifiedUser";
import Schedule from "@material-ui/icons/Schedule";
import Badge from "@material-ui/core/Badge";
import { withStyles } from "@material-ui/core/styles";

const baseStyle = {
  textDecoration: "none"
};
const styles = theme => ({
  margin: {
    margin: theme.spacing.unit * 2
  },
  padding: {
    padding: `0 ${theme.spacing.unit * 2}px`
  }
});

const InfoLinks = props => (
  <React.Fragment>
    <Link to="/dashboard/address" style={baseStyle}>
      <ListItem button>
        <ListItemIcon>
          <AddLocation />
        </ListItemIcon>
        <ListItemText primary="Manage Addresses" />
      </ListItem>
    </Link>
    {props.userData.isLooking && !props.userData.npiVerified && (
      <Link to="/dashboard/npi" style={baseStyle}>
        <ListItem button>
          <ListItemIcon>
            {!props.userData.npiVerified ? (
              <Badge badgeContent={"!"} color="secondary">
                <VerifyUser />
              </Badge>
            ) : (
              <VerifyUser />
            )}
          </ListItemIcon>
          <ListItemText primary="Verify NPI Number" />
        </ListItem>
      </Link>
    )}
    <Link to="/dashboard/applications" style={baseStyle}>
      <ListItem button>
        <ListItemIcon>
          <Badge
            badgeContent={props.list.items.length + props.applications.length}
            color="secondary"
          >
            <Schedule />
          </Badge>
        </ListItemIcon>
        <ListItemText primary="My Applications" />
      </ListItem>
    </Link>
  </React.Fragment>
);

export default withStyles(styles)(InfoLinks);
