import axios from "axios";
import { db } from "../firebase/firebase";
import { myFirebase } from "../firebase/firebase";

export const USER_REQUEST = "USER_REQUEST";
export const USER_SUCCESS = "USER_SUCCESS";
export const USER_FAILURE = "USER_FAILURE";
export const UPDATE_PERSONAL_INFO_SUCCESS = "UPDATE_PERSONAL_INFO_SUCCESS";
export const UPDATE_PERSONAL_INFO_REQUEST = "UPDATE_PERSONAL_INFO_REQUEST";
export const PERSONAL_INFO_SUCCESS = "PERSONAL_INFO_SUCCESS";
export const PERSONAL_INFO_REQUEST = "PERSONAL_INFO_REQUEST";

const requestUser = creds => {
  return {
    type: USER_REQUEST,
    creds
  };
};

const receiveUser = user => {
  return {
    type: USER_SUCCESS,
    user: user
  };
};

const userError = message => {
  return {
    type: USER_FAILURE,
    message
  };
};

const updatePersonalInfoRequest = () => {
  return {
    type: UPDATE_PERSONAL_INFO_REQUEST
  };
};

const updatePersonalInfoSuccess = () => {
  return {
    type: UPDATE_PERSONAL_INFO_SUCCESS
  };
};

export const currentUserData = uids => dispatch => {
  const uid = myFirebase.auth().currentUser.uid;
  const docRef = db.collection("users").doc(uid);
  dispatch(requestUser());
  docRef
    .get()
    .then(user => {
      dispatch(receiveUser(user.data()));
    })
    .catch(error => {
      dispatch(userError(error));
    });
};

export const updateProfileData = (uids, profileData, callback) => dispatch => {
  const uid = myFirebase.auth().currentUser.uid;
  const docRef = db.collection("users").doc(uid);
  dispatch(updatePersonalInfoRequest());
  docRef
    .set(profileData, {
      merge: true
    })
    .then(() => {
      dispatch(updatePersonalInfoSuccess());
      console.log("callback");
      callback();
    })
    .catch(error => {
      console.log(error);
    });
};

export const addEmailToNewsletter = email => () => {
  const docRef = db.collection("email");
  docRef.add({ email });
};
