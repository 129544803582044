import axios from "axios";
import { db } from "../firebase/firebase";
import { myFirebase } from "../firebase/firebase";
export const REQUEST_LIST = "REQUEST_LIST";
export const RECEIVE_LIST = "RECEIVE_LIST";
export const REQUEST_UPDATE_LIST = "REQUEST_UPDATE_LIST";
export const RECEIVE_UPDATE_LIST = "RECEIVE_UPDATE_LIST";
export const REQUEST_ADD_LIST = "REQUEST_ADD_LIST";
export const RECEIVE_ADD_LIST = "RECEIVE_ADD_LIST";
export const FAILURE_ADD_LIST = "FAILURE_ADD_LIST";

const requestAddList = () => {
  return {
    type: REQUEST_ADD_LIST
  };
};

const receiveAddList = () => {
  return {
    type: RECEIVE_ADD_LIST
  };
};

const requestList = () => {
  return {
    type: REQUEST_LIST
  };
};

const receiveList = data => {
  return {
    type: RECEIVE_LIST,
    payload: data
  };
};

const requestUpdateList = () => ({
  type: REQUEST_UPDATE_LIST
});

const receiveUpdateList = () => ({
  type: RECEIVE_UPDATE_LIST
});

export const fetchAddList = payload => dispatch => {
  const docRef = db.collection("jobs");
  const userId = myFirebase.auth().currentUser.uid;
  dispatch(requestAddList());
  docRef.add({ userId, ...payload }).then(() => {
    dispatch(receiveAddList());
    dispatch(fetchList());
  });
};

export const fetchList = () => dispatch => {
  const docRef = db.collection("jobs");
  const docRef2 = db.collection("applications");
  const userId = myFirebase.auth().currentUser.uid;
  dispatch(requestList());
  docRef
    .where("userId", "==", userId)
    .get()
    .then(querySnapshot => {
      const data = [];
      querySnapshot.forEach(doc => {
        data.push({ ...doc.data(), id: doc.id });
      });
      if (data.length > 0) {
        data.forEach((innerDoc, index) => {
          docRef2
            .where("active", "==", true)
            .where("jobId", "==", innerDoc.id)
            .get()
            .then(querySnapshot2 => {
              data[index].applications = [];
              querySnapshot2.forEach(lastDoc => {
                data[index].applications.push({
                  ...lastDoc.data(),
                  id: lastDoc.id
                });
              });
              if (index === data.length - 1) {
                dispatch(receiveList(data));
              }
            });
        });
      }
    });
};

export const updateList = (id, payload) => dispatch => {
  const docRef = db.collection("jobs").doc(id);
  dispatch(requestUpdateList());
  docRef.update({ ...payload }).then(() => {
    dispatch(receiveUpdateList());
    dispatch(fetchList());
  });
};
