import React from "react";

import { connect } from "react-redux";
import { fetchList } from "../actions/list";
import { fetchApplications } from "../actions/applications";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import ProfileMenu from "./ProfileMenu";
import Profile from "../components/Profile";

import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { Route, Switch } from "react-router-dom";
import Browse from "./Browse";
import ListComponent from "./List";
import AddAddress from "./AddAddress";
import Applications from "./Applications";
import Groups from "./Groups";
import EditProfile from "../components/EditProfile";

import DashOverview from "./DashOverview";
import InteralRoute from "../components/InternalRoute";
import DrawerLinks from "../components/DrawerLinks";
import InfoLinks from "../components/InfoLinks";
import AccountDriver from "../components/overview/AccountDriver";

const drawerWidth = 240;

const styles = theme => ({
  root: {
    flexGrow: 1,
    minHeight: "100vh",
    zIndex: 1,
    overflow: "hidden",
    position: "relative",
    display: "flex"
  },
  flexGrow: {
    flexGrow: 1
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36
  },
  hide: {
    display: "none"
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing.unit * 7,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing.unit * 9
    }
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing.unit * 3
  }
});

class Dashboard extends React.Component {
  state = {
    open: true
  };
  componentDidMount() {
    this.props.dispatch(fetchList());
    this.props.dispatch(fetchApplications());
  }
  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };
  render() {
    const { classes, theme } = this.props;

    return (
      <div className={classes.root}>
        {Object.keys(this.props.userData).length !== 0 &&
          this.props.userData.isLooking === undefined && <AccountDriver />}
        <AppBar
          position="absolute"
          className={classNames(
            classes.appBar,
            this.state.open && classes.appBarShift
          )}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={this.handleDrawerOpen}
              className={classNames(
                classes.menuButton,
                this.state.open && classes.hide
              )}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              variant="title"
              color="inherit"
              noWrap
              className={classes.flexGrow}
            >
              NotifEyes
            </Typography>
            <ProfileMenu dispatch={this.props.dispatch} />
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          classes={{
            paper: classNames(
              classes.drawerPaper,
              !this.state.open && classes.drawerPaperClose
            )
          }}
          open={this.state.open}
        >
          <div className={classes.toolbar}>
            <IconButton onClick={this.handleDrawerClose}>
              {theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </div>
          <Divider />
          <List>
            <DrawerLinks userData={this.props.userData} />
          </List>
          <Divider />
          <List>
            <InfoLinks
              userData={this.props.userData}
              list={this.props.list}
              applications={this.props.applications.items}
            />
          </List>
        </Drawer>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Switch>
            <Route exact path="/dashboard" component={Browse} />
            <InteralRoute
              path="/dashboard/browse"
              redirect="/dashboard"
              component={Browse}
              isAuthenticated={true}
            />
            <InteralRoute
              path="/dashboard/list"
              redirect="/dashboard"
              component={ListComponent}
              isAuthenticated={true}
            />
            <InteralRoute
              path="/dashboard/groups"
              redirect="/dashboard"
              component={Groups}
              isAuthenticated={true}
            />
            <InteralRoute
              path="/dashboard/address"
              redirect="/dashboard"
              component={AddAddress}
              isAuthenticated={true}
            />
            <InteralRoute
              path="/dashboard/applications"
              redirect="/dashboard"
              component={Applications}
              isAuthenticated={true}
            />
            <InteralRoute
              path="/dashboard/profile/edit"
              redirect="/dashboard"
              component={EditProfile}
              isAuthenticated={true}
            />
            <InteralRoute
              path="/dashboard/profile"
              redirect="/dashboard"
              component={Profile}
              isAuthenticated={true}
            />
            <Route component={DashOverview} />
          </Switch>
        </main>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    userData: state.user.user,
    list: state.list,
    applications: state.applications
  };
}
export default withStyles(styles, { withTheme: true })(
  connect(mapStateToProps)(Dashboard)
);
