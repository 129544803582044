import {
  RECEIVE_ADD_APPLY,
  REQUEST_ADD_APPLY,
  REQUEST_APPLICATIONS,
  RECEIVE_APPLICATIONS,
  REQUEST_JOB_APPLICATIONS,
  RECEIVE_JOB_APPLICATIONS
} from "../actions/applications";

export const applications = (
  state = {
    isFetching: false,
    isAddFetching: false,
    items: [],
    myApps: {}
  },
  action
) => {
  switch (action.type) {
    case REQUEST_ADD_APPLY:
      return {
        ...state,
        isAddFetching: true
      };
    case RECEIVE_ADD_APPLY:
      return {
        ...state,
        isAddFetching: false
      };

    case REQUEST_APPLICATIONS:
      return {
        ...state,
        isFetching: true
      };
    case RECEIVE_APPLICATIONS:
      return {
        ...state,
        isFetching: false,
        items: action.payload
      };
    case REQUEST_JOB_APPLICATIONS:
      return {
        ...state,
        isFetching: true
      };
    case RECEIVE_JOB_APPLICATIONS:
      return {
        ...state,
        isFetching: false,
        myApps: action.payload
      };
    default:
      return state;
  }
};
