import { combineReducers } from "redux";
import { auth, signup, verifyEmail } from "./authentication";
import { user } from "./user";
import { verifyLicense } from "./licenseVerifcation";
import { address } from "./address";
import { list } from "./list";
import { jobs } from "./browse";
import { applications } from "./applications";

const rootReducer = combineReducers({
  auth,
  signup,
  verifyEmail,
  user,
  verifyLicense,
  address,
  list,
  jobs,
  applications
});

export default rootReducer;
