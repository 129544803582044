import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import _ from "lodash";
import { connect } from "react-redux";
import { fetchApplications, updateApplication } from "../actions/applications";
import { updateList } from "../actions/list";
import Table from "@material-ui/core/Table";
import Typography from "@material-ui/core/Typography";
import TableBody from "@material-ui/core/TableBody";
import moment from "moment";
import TableCell from "@material-ui/core/TableCell";
import Grid from "@material-ui/core/Grid";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Chip from "@material-ui/core/Chip";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import Icon from "@material-ui/core/Icon";
import AccountCircle from "@material-ui/icons/AccountCircle";
import { fetchList } from "../actions/list";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto"
  },
  paper: {
    marginTop: 10,
    padding: 20
  },
  button: {
    margin: theme.spacing.unit
  },
  paperRight: {
    marginTop: 10,
    padding: 0,
    height: 385
  },
  action: {
    backgroundColor: "#FF3333",
    color: "#FFF",
    float: "left"
  },
  row: {
    "&:hover": {
      backgroundColor: "#EEE"
    },
    padding: 0,
    margin: 0
  },
  table: {
    padding: 0,
    margin: 0
  },
  actionCell: {
    textAlign: "right"
  },
  addButton: {
    marginTop: 20,
    marginLeft: 20
  }
});

let id = 0;
function createData(name, address, status, actionRequired) {
  id += 1;
  return { id, name, address, status, actionRequired };
}

const rows = [
  createData(
    "Optomotrists in Training",
    "Area 51, Desert, NV",
    "Offer Made",
    true
  ),
  createData(
    "Chattanooga EyeCare",
    "110 Market Street, Chattanooga, TN",
    "Rejected",
    false
  ),
  createData(
    "Ross EyeCare",
    "115 Market Street, Chattanooga, TN",
    "Accepted",
    false
  )
];

class JobsTable extends React.Component {
  state = { row: "", profile: null };
  componentDidMount() {
    console.log(this.props.list);
    //this.props.dispatch(fetchApplicationsToMyJobs());
  }
  handleSelectRow = row => () => {
    this.props.hideOther(2);
    this.setState({ row: row.id });
  };

  getSelectedRow = () => {
    const rowId = this.state.row;
    return _.find(this.props.list, { id: rowId });
  };

  handleAccept = appId => () => {
    this.props.dispatch(updateApplication(appId, { status: "Accepted" }));
    this.props.dispatch(fetchList());
  };

  handleHide = () => {
    this.props.hideOther(null);
    this.setState({ row: "" });
  };

  handleWithdraw = appId => () => {
    this.props.dispatch(updateList(appId, { status: "Withdrawn" }));
    this.props.dispatch(fetchList());
    this.handleHide();
  };

  handleDeny = applicationId => () => {
    this.props.dispatch(
      updateApplication(applicationId, { status: "Refused" })
    );
    this.props.dispatch(fetchList());
  };

  isOffer = () => {
    const offered = _.find(this.getSelectedRow().applications, {
      status: "Offered"
    });
    return !offered;
  };

  isAccepted = () => {
    const offered = _.find(this.getSelectedRow().applications, {
      status: "Accepted"
    });

    return !offered;
  };

  showProfile = app => () => {
    this.setState({ profile: app });
  };

  hideProfile = () => {
    this.setState({ profile: null });
  };

  render() {
    const { classes, list } = this.props;
    const selectedRow = this.getSelectedRow();
    console.log(list);
    return (
      <React.Fragment>
        {!this.state.row ? (
          <Paper className={classes.root}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Business Name</TableCell>
                  <TableCell>Address</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {list.map(row => {
                  console.log("lol", row);
                  return (
                    <TableRow
                      key={row.id}
                      className={classes.row}
                      onClick={this.handleSelectRow(row)}
                    >
                      <TableCell component="th" scope="row">
                        {row.job}
                      </TableCell>
                      <TableCell>
                        {row.street}, {row.city}
                      </TableCell>
                      <TableCell>{row.status}</TableCell>
                      <TableCell className={classes.actionCell}>
                        <ChevronRight />
                        {row.applications && row.applications.length > 0 && (
                          <Chip
                            label={row.applications.length}
                            className={classes.action}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Paper>
        ) : (
            <Grid container xs={12} spacing={24}>
              <Grid item xs={10}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={this.handleHide}
                >
                  Back
                {/* This Button uses a Font Icon, see the installation instructions in the docs. */}
                </Button>{" "}
                <Paper className={classes.paper}>
                  <Typography component="h2" variant="h6" gutterBottom>
                    {selectedRow.job}
                  </Typography>
                  <Typography component="p" gutterBottom>
                    <strong>Status</strong> - <Chip label={selectedRow.status} />
                  </Typography>
                  <p>
                    <strong>Job Description</strong>
                  </p>
                  <p>{selectedRow.description}</p>
                  <p>
                    <strong>Requested Start Date</strong>
                  </p>
                  <p>
                    {moment(selectedRow.startDate.timestamp).format("MM/DD/YYYY")}
                  </p>
                  <p>
                    <strong>Address</strong>
                  </p>
                  <p>
                    {selectedRow.street}, {selectedRow.city}
                  </p>
                  <p>
                    <strong>Amount</strong>
                  </p>
                  <p>${selectedRow.amount}/Hour</p>
                </Paper>
                <Paper className={classes.paper} style={{ marginTop: 24 }}>
                  <Typography component="h2" variant="h6" gutterBottom>
                    Applicants
                </Typography>{" "}
                  {selectedRow.applications.length > 0 ? (
                    <Table className={classes.table} size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>First Name</TableCell>
                          <TableCell>Last Name</TableCell>
                          <TableCell>Status</TableCell>
                          <TableCell />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {selectedRow.applications.map(row => {
                          return (
                            <React.Fragment>
                              <TableRow key={row.id} className={classes.row}>
                                <TableCell component="th" scope="row">
                                  {row.firstName}
                                </TableCell>
                                <TableCell>{row.lastName}</TableCell>
                                <TableCell>{row.status}</TableCell>
                                <TableCell className={classes.actionCell}>
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    onClick={this.showProfile(row)}
                                  >
                                    Profile{" "}
                                    <AccountCircle
                                      className={classes.rightIcon}
                                    />
                                  </Button>
                                  {row.status !== "Refused" &&
                                    row.status !== "Offered" &&
                                    this.isOffer() &&
                                    this.isAccepted() && (
                                      <Button
                                        variant="contained"
                                        color="primary"
                                        className={classes.button}
                                        onClick={this.handleAccept(row.id)}
                                      >
                                        Offer
                                      {/* This Button uses a Font Icon, see the installation instructions in the docs. */}
                                        <Icon
                                          className={classes.rightIcon}
                                          style={{ marginLeft: 5 }}
                                        >
                                          Send
                                      </Icon>
                                      </Button>
                                    )}
                                  {row.status !== "Refused" && this.isAccepted() && (
                                    <Button
                                      variant="contained"
                                      color="secondary"
                                      className={classes.button}
                                      onClick={this.handleDeny(row.id)}
                                    >
                                      Refuse
                                    <DeleteIcon className={classes.rightIcon} />
                                    </Button>
                                  )}
                                </TableCell>
                              </TableRow>
                              {this.state.profile && (
                                <Dialog
                                  open={this.state.profile}
                                  onClose={this.hideProfile}
                                  aria-labelledby="form-dialog-title"
                                >
                                  <DialogTitle id="form-dialog-title">
                                    Profile
                                </DialogTitle>
                                  <DialogContent>
                                    <DialogContentText>
                                      {this.state.profile.firstName}{" "}
                                      {this.state.profile.lastName}
                                    </DialogContentText>
                                  </DialogContent>
                                </Dialog>
                              )}
                            </React.Fragment>
                          );
                        })}
                      </TableBody>
                    </Table>
                  ) : (
                      <Typography component="h2" variant="h7" gutterBottom>
                        None
                  </Typography>
                    )}
                </Paper>
                <Paper className={classes.paper} style={{ marginTop: 24 }}>
                  {selectedRow.status !== "Withdrawn" && (
                    <Button
                      variant="contained"
                      color="secondary"
                      className={classes.button}
                      onClick={this.handleWithdraw(selectedRow.id)}
                    >
                      Withdraw Job
                    <DeleteIcon className={classes.rightIcon} />
                    </Button>
                  )}
                </Paper>
              </Grid>
            </Grid>
          )}
      </React.Fragment>
    );
  }
}

JobsTable.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    list: state.list.items
  };
};

export default connect(mapStateToProps)(withStyles(styles)(JobsTable));
