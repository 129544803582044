import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Redirect from "react-router";

const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

const styles = theme => ({
  root: {
    width: "100%",
    margin: theme.spacing.unit * 3,
    overflowX: "auto"
  },
  table: {
    minWidth: 300
  },
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default
    }
  }
});

function LicenseTable(props) {
  const { classes } = props;

  return (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <CustomTableCell>Select</CustomTableCell>
            <CustomTableCell>First Name</CustomTableCell>
            <CustomTableCell>Last Name</CustomTableCell>
            <CustomTableCell numeric>NPI Number</CustomTableCell>
            <CustomTableCell>Credential</CustomTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.verifyResults.map(row => {
            return (
              <TableRow className={classes.row} key={row.number}>
                <CustomTableCell component="th" scope="row">
                  <Button
                    onClick={() => {
                      props.handleSelectLicense(row);
                    }}
                    variant="contained"
                    color="primary"
                  >
                    {!props.selectedLicense ? "This is Me" : "Selected"}
                  </Button>
                </CustomTableCell>
                <CustomTableCell component="th" scope="row">
                  {row.basic.first_name}
                </CustomTableCell>
                <CustomTableCell component="th" scope="row">
                  {row.basic.last_name}
                </CustomTableCell>
                <CustomTableCell numeric>{row.number}</CustomTableCell>
                <CustomTableCell component="th" scope="row">
                  {row.basic.credential}
                </CustomTableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Paper>
  );
}

LicenseTable.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(LicenseTable);
