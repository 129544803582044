import React from "react";
import { connect } from "react-redux";
import Typography from "@material-ui/core/Typography";
import AddressTable from "../components/AddressTable";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";

class AddAddress extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Grid className={classes.root} container spacing={24}>
          <Grid container spacing={16} justify="center">
            <Grid item xs={12} sm={12} md={8} lg={8}>
              <div
                style={{
                  padding: "20px",
                  textAlign: "left",
                  marginBottom: "10px"
                }}
              >
                <Typography variant="h4">Manage Addresses</Typography>
                <hr />
              </div>
              <AddressTable dispatch={this.props.dispatch} />
            </Grid>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: "5em"
  },
  card: {
    display: "flex"
  },
  details: {
    display: "flex",
    flexDirection: "column"
  },
  content: {
    flex: "1 0 auto"
  },
  avatar: {
    borderRadius: "100%",
    width: "100%",
    maxHeight: "150px",
    maxWidth: "150px",
    minWidth: "150px"
  },
  uploadButton: {
    whiteSpace: "nowrap",
    display: "block"
  },
  nowrap: {
    whiteSpace: "nowrap"
  },
  fr: {
    textAlign: "right"
  },
  textLeft: {
    textAlign: "left"
  },
  fl: {
    float: "left"
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing.unit,
    paddingBottom: theme.spacing.unit
  },
  playIcon: {
    height: 38,
    width: 38
  },
  info: {
    borderColor: "#2196F3",
    color: "dodgerblue"
  },
  infoHover: {
    background: "#2196F3",
    color: "white"
  },
  sendMsg: {
    paddingTop: 20
  },
  verticalAlign: {
    verticalAlign: "middle"
  },
  nudgeBottom: {
    marginBottom: "5px"
  },
  marginLeft: "5%",
  center: { textAlign: "center", justify: "center" },
  textField: {
    width: 200
  },
  spacing: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit
  }
});

export default withStyles(styles)(connect()(AddAddress));
